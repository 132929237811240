import React from "react";
import PaymentMethod from "../../models/PaymentMethod";

class Security extends React.Component {
    render() {
        const show = this.props.methodType === PaymentMethod.typeCreditCard;

        return (
            <React.Fragment>
                {show &&
                <React.Fragment>
                    <div className="badge-security pci-dss mr-2"/>
                    <div className="badge-security ssl mr-2"/>
                </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default Security;
