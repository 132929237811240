import React from "react";
import {Button} from "reactstrap";
import PropTypes from "prop-types";

class ActionButton extends React.Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        event.preventDefault();

        const {isLoading, onClick} = this.props;
        if (!isLoading) {
            onClick();
        }
    }

    render() {
        const {isLoading, isDisabled, ariaLabel, children} = this.props;

        return (
            <Button color="pink" size="lg"
                    className="action-button"
                    type={"submit"}
                    aria-label={ariaLabel}
                    disabled={isDisabled || isLoading}
                    onClick={this.onClick}>
                {isLoading ? <div className="spinner"/> : children}
            </Button>
        );
    }
}

ActionButton.propTypes = {
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    ariaLabel: PropTypes.string,
    children: PropTypes.array,
}

export default ActionButton;
