import React from "react";
import Translate from "./Translate";

class FatalError extends React.Component {
    render() {
        const message = this.props.message;
        if (message === null) {
            return null;
        }

        return (
            <div className="fatal-error-container">
                <div className="alert alert-embarrassment">
                    <p className="h4 alert-heading">
                        <Translate>error.fatal.title</Translate>
                    </p>
                    <p>
                        <Translate>error.fatal.description</Translate>
                    </p>
                    {message && <hr/>}
                    {message &&
                        <small>
                            <Translate>error.fatal.message</Translate>{": " + message}
                        </small>
                    }
                </div>
            </div>
        );
    }
}

export default FatalError;
