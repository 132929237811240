import PaymentMethod from "./PaymentMethod";

class PaymentModel {
    constructor(price, methods, description) {
        this.price = price;
        this.methods = methods;
        this.description = description;

        this.selectedMethodType = null;
        if (this.methods.length === 1) {
            this.selectedMethodType = this.methods[0].type;
        }
    }

    getRequestData() {
        /** @type{PaymentMethod} */
        const method = this.getSelectedMethod();

        if (method instanceof PaymentMethod) {
            return method.getRequestData();
        }

        return null;
    }

    updateRequestData() {
        /** @type{PaymentMethod} */
        const method = this.getSelectedMethod();

        if (method instanceof PaymentMethod) {
            return method.updateRequestData();
        }

        return null;
    }

    getSelectedMethod() {
        if (this.selectedMethodType === null) {
            return null;
        }

        for (const i in this.methods) {
            if (this.methods[i].type === this.selectedMethodType) {
                return this.methods[i];
            }
        }

        return null;
    }

    isDCCPossible() {
        const sm = this.getSelectedMethod();
        if (sm instanceof PaymentMethod) {
            return sm.isDCCPossible();
        }

        return false;
    }

    isAddonsBuyingPossible() {
        const sm = this.getSelectedMethod();
        if (sm instanceof PaymentMethod) {
            return sm.areAddonsBuyingPossible();
        }

        return false;
    }

    isDirectDebit() {
        const sm = this.getSelectedMethod();
        if (sm instanceof PaymentMethod) {
            if (sm.type === PaymentMethod.typeDirectDebit) {
                return true;
            }
        }

        return false;
    }

    isMethodSelected() {
        const sm = this.getSelectedMethod();
        return sm instanceof PaymentMethod;
    }
}

export default PaymentModel;
