import Money from "../library/Money";

class ExtraChargesModel {
    constructor(charges, currency, minor_currency_digits) {
        let totalPrice = new Money(0, currency, minor_currency_digits);

        /** @type {ExtraChargeModel[]} */
        this.items = charges.map(charge => {
            const price = new Money(charge.amount, currency, minor_currency_digits);
            totalPrice = totalPrice.add(price);

            return new ExtraChargeModel(price, charge.description)
        })

        this.totalPrice = totalPrice;
    }

    isEmpty() {
        return this.items.length <= 0;
    }
}

class ExtraChargeModel {
    constructor(price, description) {
        this.price = price;
        this.description = description;
    }
}

export default ExtraChargesModel;
