export default {
    "country.at": "Austria",
    "country.be": "Bélgica",
    "country.ch": "Suiza",
    "country.de": "Alemania",
    "country.es": "España",
    "country.it": "Italia",
    "country.nl": "Países Bajos",

    "select": "Seleccionar",

    "page.title": "Caja",

    "payment_method.credit_card.title": "Tarjeta",
    "payment_method.credit_card.number": "Número de la tarjeta",
    "payment_method.credit_card.holder": "Nombre en la tarjeta",
    "payment_method.credit_card.expiry": "Fecha de expiración de la tarjeta",
    "payment_method.credit_card.cvc": "CVC",

    "payment_method.direct_debit.title": "Débito directo SEPA",
    "payment_method.direct_debit.email_address": "Dirección de correo electrónico",
    "payment_method.direct_debit.holder": "Nombre del titular de la cuenta",
    "payment_method.direct_debit.iban": "IBAN",
    "payment_method.direct_debit.bank_name": "Nombre del banco",
    "payment_method.direct_debit.bic": "BIC",

    "payment_method.ideal.title": "iDEAL",
    "payment_method.ideal.bic": "Nombre del banco",

    "payment_method.sofort.title": "Sofortüberweisung",
    "payment_method.sofort.bank_country": "País del banco",

    "payment_method.alipay.title": "Alipay",
    "payment_method.twint.title": "TWINT",
    "payment_method.apple_pay.title": "Apple Pay",
    "payment_method.google_pay.title": "Google Pay",

    "payment_method.giropay.title": "Giropay",
    "payment_method.giropay.holder_name": "Nombre del titular de la cuenta",

    "payment_method.eps.title": "EPS",
    "payment_method.eps.holder_name": "Nombre del titular de la cuenta",

    "payment_method.invoice.title": "Pay by Invoice",
    "payment_method.invoice.type.individual": "Private customer",
    "payment_method.invoice.type.company": "Business customer",
    "payment_method.invoice.first_name": "First Name",
    "payment_method.invoice.last_name": "Last Name",
    "payment_method.invoice.company_name": "Company Name",
    "payment_method.invoice.city": "City",
    "payment_method.invoice.zip_code": "Zip code",
    "payment_method.invoice.address": "Address",
    "payment_method.invoice.email": "Email",
    "payment_method.invoice.language": "Language",
    "payment_method.invoice.language.de": "German",
    "payment_method.invoice.language.fr": "French",
    "payment_method.invoice.region_restriction": "Pay by invoice is currently only available for residents of Switzerland. A valid Swiss mailing address is required.",

    "payment_method.crypto.title": "Pay by crypto currency",

    "payment_method.postfinance.title": "PostFinance",
    "payment_method.postfinance.auth_type": "Payment Type",
    "payment_method.postfinance.auth_type.card": "Card / App",
    "payment_method.postfinance.auth_type.efinance": "E-Finance",
    "payment_method.postfinance.language": "Language",
    "payment_method.postfinance.language.de": "German",
    "payment_method.postfinance.language.fr": "French",
    "payment_method.postfinance.language.it": "Italian",
    "payment_method.postfinance.language.en": "English",

    "insurance.refund_protect.title": "Reserva reembolsable",
    "insurance.trekksoft_standard.title": "Seguro de cancelación",

    "insurance.description.line1": "Para que no tenga que asumir el riesgo financiero de la cancelación, le recomendamos que complete nuestro seguro de gastos de cancelación.",
    "insurance.description.line2": "Eventos asegurados",
    "insurance.description.line3": "(extracto de las condiciones generales del seguro)",
    "insurance.description.line4": "Puedes encontrar el servicio exacto en",
    "insurance.description.line5": "los términos generales del seguro",
    "insurance.description.line6": "Si tiene alguna pregunta, llámenos o escríbamos un email",
    "insurance.coverage.line1": "Enfermedad, embarazo, accidente, muerte",
    "insurance.coverage.line2": "Daños a la propiedad residencial en el hogar",
    "insurance.coverage.line3": "Retraso y cancelación del medio de transporte en el viaje de ida",
    "insurance.coverage.line4": "Huelgas y peligros en el país de destino",
    "insurance.coverage.line5": "Desempleo / comienzo inesperado del empleo",

    "cart.title": "Tu cesta",
    "cart.show": "Mostrar cesta",
    "cart.hide": "Esconder cesta",

    "person.title": "Invitado",
    "person.firstname": "Nombre",
    "person.lastname": "Apellido",

    "terms.dialog.title": "Términos y condiciones",

    "cvc.help.title": "¿Dónde puedo encontrar mi código de seguridad (CVV/CVC) en la tarjeta?",
    "cvc.help.maestro": "Belgian debit cards do not have a Card Verification Code (CVC). To confirm the payment, please leave it blank. /  Enter 000 or 999 to confirm payment.",

    "dialog.button.close": "Cerrar",

    "error.payment_method.no_payment_method_is_configured": "No se ha configurado ningún método de pago. Por favor, póngase en contacto con el vendedor.",
    "error.validation.invalid_value": "Valor incorrecto",
    "error.validation.check_input": "Por favor, compruebe sus datos",
    "error.fatal.title": "Sucedió algo inesperado",
    "error.fatal.description": "Lo sentimos, pero algo salió mal. Por favor, inténtelo de nuevo o póngase en contacto con nosotros.",
    "error.fatal.message": "Mensaje de error",
    "error.payment.payment_failed": "Pago malsukcesis. Por favor, inténtelo de nuevo o póngase en contacto con nosotros.",
    "error.payment.3ds_authentication_failed": "Autenticación 3DS falló. Por favor, inténtelo de nuevo o póngase en contacto con nosotros.",

    "step.title.addons": "Pago reembolsable",
    "step.title.payment": "Método de pago",
    "step.title.google_pay": "Google Pay",

    "step.breadcrumb.addons": "Pago reembolsable",
    "step.breadcrumb.payment": "Pago",
    "step.breadcrumb.google_pay": "Google Pay",

    "action.next": "Siguiente",
    "action.pay": "Pagar",
    "action.more": "Más información",

    "action.abort.title": "Anular el pago",

    "dcc.title": "Elige moneda para el pago",
    "dcc.alternate_payment_method_description": "Este método de pago sólo permite %currency% como moneda. Si desea pagar en otra moneda, seleccione otra forma de pago.",
    "dcc.fees_info": "incluye todos los honorarios y cargos",
    "dcc.conversion_by_payyo": "%price%, conversión por Payyo",
    "dcc.conversion_by_issuer": "%price%, conversión del banco",

    "footer.line1": "Por Favor entre en contacto con %supportEmail% para todas las consultas relacionadas con el pago realizado.",
    "footer.line2": "© 2010 - %year% TrekkSoft AG. Hecho en Suiza.",

    "countdown.title": "%time% restante para finalizar el pedido",

    "rebate.title": "Descuento",

    "refund_protect.recommended": "Recomendado",
    "refund_protect.protection_selected": "Pago Reembolsable",
    "refund_protect.instructions_1": "Actualice su reserva y reciba un reembolso del 100%, en caso de cancelación antes de la fecha del servicio, si no puede asistir y puede demostrarlo por alguno de los motivos definidos en nuestros Términos y condiciones.",
    "refund_protect.instructions_2": "Desarrollado por",
    "refund_protect.instructions_powered_by": "Refundable.me",
    "refund_protect.reasons.covid_19": "COVID-19 infección y aislamiento",
    "refund_protect.reasons.covid_19_link": "Ver detalles",
    "refund_protect.reasons.reasons": "Solicite el reembolso completo en caso de",
    "refund_protect.reasons.reason_0": "Enfermedad o lesión de última hora (inc COVID-19)",
    "refund_protect.reasons.reason_1": "Enfermedad Accidente y Lesión",
    "refund_protect.reasons.reason_2": "Emergencias domésticas y familiares",
    "refund_protect.reasons.reason_3": "Fallo de transporte",
    "refund_protect.reasons.reason_4": "Robo de Documentos",
    "refund_protect.reasons.reason_5": "Condición médica preexistente",
    "refund_protect.reasons.reason_6": "Citación Judicial",
    "refund_protect.reasons.reason_7": "Falla de vehículo privado",
    "refund_protect.reasons.reason_8": "Convocatoria por Servicios de Emergencia",
    "refund_protect.reasons.reason_9": "Y muchos mas ...",
    "refund_protect.reasons.see_more": "Y más",
    "refund_protect.protection_rejected": "Condiciones básicas de reembolso",
    "refund_protect.protection_rejected_terms": "Acepto y estoy de acuerdo con la política de reembolso estándar del proveedor",
};
