class TermsModel {

    constructor(merchantId, softDescriptor, areTermsAccepted) {
        if (areTermsAccepted === undefined) {
            areTermsAccepted = 0;
        }

        this.merchantId = merchantId;
        this.currency = undefined;
        this.amount = undefined;
        this.softDescriptor = softDescriptor;
        this.areTermsAccepted = areTermsAccepted;
        this.isReady = false;
        this.paymentMethodType = undefined;
        this.paymentMethodToken = undefined;
        this.acceptanceText = undefined;
        this.fullText = undefined;
    }

    reset() {
        this.fullText = undefined;
        this.paymentMethodType = undefined;
        this.isReady = false;
        this.areTermsAccepted = false;
        this.acceptanceText = false;
    }
}

export default TermsModel;
