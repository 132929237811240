import React from "react";
import {CustomInput, Fade} from 'reactstrap';
import DccModel from "../../models/DccModel";
import Translate, {WithLanguageContext} from "../Translate";
import Random from "../../library/Random";
import PaymentMethod from "../../models/PaymentMethod";

class DCC extends React.Component {

    constructor(props) {
        super(props);
        this.accept = this.accept.bind(this);
        this.reject = this.reject.bind(this);
    }

    componentDidMount() {
        this.id = Random.id();
    }

    accept() {
        this.props.onChange(true);
    }

    reject() {
        this.props.onChange(false);
    }

    render() {
        /** @type {DccModel}*/
        const {dcc, paymentMethod, isMobile, isAccepted, t} = this.props;
        if (!(dcc instanceof DccModel)) {
            return null;
        }

        const quote = dcc.quote;
        let inner = '';

        const controlName = "dcc-" + this.id;
        const acceptControlId = "dcc-accept-" + this.id;
        const rejectControlId = "dcc-reject-" + this.id;
        const dccLabelId = "dcc-quote-label-" + this.id;

        const quoteLabel = (
            <span className={"description text-nowrap"}>
                {"(1 " + dcc.price.getCurrency() + " = " + quote.exchangeRate + " " + quote.price.getCurrency() + ", "}
                    <span className={"dcc-fees-info"}>
                        <Translate>dcc.fees_info</Translate>
                    </span>
                {") "}
            </span>
        );

        if (paymentMethod instanceof PaymentMethod && !paymentMethod.hasDccOptions()) {
            inner = (
                <div className={"alert alert-dcc clearfix"}>
                    <React.Fragment>{quoteLabel}</React.Fragment>
                    <Translate currency={quote.price.getCurrency()}>
                        dcc.alternate_payment_method_description
                    </Translate>
                </div>
            );
        } else {
            inner = (
                <div className="alert alert-dcc clearfix" role={"radiogroup"} aria-labelledby={dccLabelId}>
                    <h3 className="h5 mb-3" id={dccLabelId}>
                        <Translate>dcc.title</Translate>
                    </h3>
                    <div role={"presentation"}>
                        <CustomInput id={acceptControlId}
                                     name={controlName}
                                     value={"accepted"}
                                     type={"radio"}
                                     onChange={this.accept}
                                     checked={isAccepted}
                                     className={"mb-2"}
                                     aria-checked={isAccepted ? "true" : "false"}>
                            <label className={"custom-control-label"} htmlFor={acceptControlId}>
                                <React.Fragment>
                                    <Translate price={quote.price.format()}>dcc.conversion_by_payyo</Translate>
                                    <br/>
                                    {quoteLabel}
                                </React.Fragment>
                            </label>
                        </CustomInput>
                    </div>
                    <div role={"presentation"}>
                        <CustomInput id={rejectControlId}
                                     name={controlName}
                                     value={"rejected"}
                                     type={"radio"}
                                     onChange={this.reject}
                                     checked={!isAccepted}
                                     className={"mb-2"}
                                     aria-checked={!isAccepted ? "true" : "false"}>

                            <label className={"custom-control-label"} htmlFor={rejectControlId}>
                                <Translate price={dcc.price.format()}>dcc.conversion_by_issuer</Translate>
                            </label>
                        </CustomInput>
                    </div>
                </div>
            );
        }

        if (isMobile) {
            return (inner);
        } else {
            return (
                <Fade>
                    <div className="d-none d-md-block">
                        {inner}
                    </div>
                </Fade>
            );
        }
    }
}

export default WithLanguageContext(DCC);
