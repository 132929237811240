import React from "react";
import Translate from "./Translate";

class StepTitle extends React.Component {
    render() {
        const {steps, id} = this.props;

        let index = null;
        if (steps.hasMultipleSteps()) {
            index = steps.getCurrentStepIndex() + ". ";
        }

        return (
            <h2 className="h4" id={id}>
                {index}
                <Translate>{"step.title." + steps.currentStep}</Translate>
            </h2>
        );
    }
}

export default StepTitle;
