import React from "react";
import {FormFeedback, Input, Label} from "reactstrap";
import Validator from "../../../library/Validator";
import Translate from "../../Translate";

class Giropay extends React.Component {

    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleHolderName = this.handleHolderName.bind(this);

        if (this.props.data.holder === undefined) {
            this.props.data.holder = {name: ""};
        }

        this.validate();
    }

    validate() {
        const data = this.props.data;

        let isValid = true;
        if (!this.isValidHolderName(data.holder.name)) {
            isValid = false;
        }

        Validator.markAs(data, isValid);

        this.props.validate();
    }

    onChange() {
        this.validate();
        this.props.onChange();
    }

    handleHolderName(event) {
        this.props.data.holder.name = event.target.value;
        this.onChange();
    }

    isValidHolderName(name) {
        if (this.isHolderNameRequired()) {
            return name.length > 0;
        }

        return true;
    }

    isHolderNameRequired() {
        return this.props.extraFields.includes("holder_name");
    }

    render() {
        const {data, showValidationErrors} = this.props;

        if (!this.isHolderNameRequired()) {
            return null;
        }

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <Label for="giropay-holder-name" className="required">
                            <Translate>payment_method.giropay.holder_name</Translate>
                        </Label>
                        <Input type="text"
                               id="giropay-holder-name"
                               autoComplete="off"
                               placeholder="Jane Doe"
                               value={data.holder.name}
                               onChange={this.handleHolderName}
                               invalid={showValidationErrors && !this.isValidHolderName(data.holder.name)}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Giropay;
