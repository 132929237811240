import React from "react";
import InsuranceQuoteModel from "../models/InsuranceQuoteModel";
import AddonsModel from "../models/AddonsModel";
import TrekkSoftStandard from "./Insurance/TrekkSoftStandard";
import PropTypes from "prop-types";
import RefundWidget from "./RefundProtect/RefundWidget";

const REFUND_PROTECT_INSURANCE_PLAN_ID = 'refund_protect';

class Addons extends React.Component {
    constructor(props) {
        super(props);
        this.onInsuranceShow = this.onInsuranceShow.bind(this);
        this.state = {insuranceIsShown: this.isInsuranceChecked()};
    }

    isInsuranceChecked() {
        const {addons} = this.props;
        return addons.isSelected(AddonsModel.addonInsurance);
    }

    onInsuranceShow(insuranceIsShown) {
        this.setState({insuranceIsShown: insuranceIsShown});
    }

    render() {
        return <React.Fragment>
            {this.renderInsurance()}
        </React.Fragment>;
    }

    renderInsurance() {
        const {insuranceQuote, showValidationErrors, onChange} = this.props;
        const isInsuranceChecked = this.isInsuranceChecked();
        const onInsuranceCheck = (checked, addon) => onChange(AddonsModel.addonInsurance, checked, addon);

        if (!(insuranceQuote instanceof InsuranceQuoteModel)) {
            return null;
        }

        let fragment = <TrekkSoftStandard insuranceQuote={insuranceQuote}
                                          onChange={onInsuranceCheck}
                                          showValidationErrors={showValidationErrors && isInsuranceChecked}
                                          isChecked={isInsuranceChecked}/>;

        if (insuranceQuote.insurancePlanId === REFUND_PROTECT_INSURANCE_PLAN_ID) {
            fragment = <RefundWidget insuranceQuote={insuranceQuote}
                                     onChange={onInsuranceCheck}
                                     isChecked={isInsuranceChecked}
                                     showValidationErrors={showValidationErrors}/>
        }


        return <div className="list-group mb-3">
            <div className={'insurance'}>{fragment}</div>
        </div>
    }
}

Addons.propTypes = {
    insuranceQuote: PropTypes.instanceOf(InsuranceQuoteModel),
    isInsuranceChecked: PropTypes.bool,
    showValidationErrors: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    areaLabelBy: PropTypes.string,
}

export default Addons;
