import React from "react";
import Person from "./Person";
import Validator from "../../library/Validator";

class Insurants extends React.Component {

    constructor(props) {
        super(props);

        this.validate = this.validate.bind(this);
        this.onChange = this.onChange.bind(this);

        this.validate(props.insuranceQuote.persons);
    }

    validate(persons) {
        let isValid = true;
        persons.forEach(function (p) {
            if (!Validator.isValid(p)) {
                isValid = false;
            }
        });
    }

    onChange(index, person) {
        const {insuranceQuote: {persons}, onChange} = this.props;
        persons[index] = person;

        this.validate(persons);
        onChange(persons);
    }

    render() {
        return (
            <div className="card-body">
                {this.props.insuranceQuote.persons.map((person, index) =>
                    <Person key={person.reference}
                            person={person}
                            onChange={(p) => this.onChange(index, p)}
                            showValidationErrors={this.props.showValidationErrors}
                            validate={this.validate}
                            index={index + 1}
                    />
                )}
            </div>
        );
    }
}

export default Insurants;
