export default {
    "country.at": "Austria",
    "country.be": "Belgio",
    "country.ch": "Svizzera",
    "country.de": "Germania",
    "country.es": "Spagna",
    "country.it": "Italia",
    "country.nl": "Olanda",

    "select": "Seleziona",

    "page.title": "Pagamento",

    "payment_method.credit_card.title": "Carta",
    "payment_method.credit_card.number": "Numero della carta",
    "payment_method.credit_card.holder": "Titolare",
    "payment_method.credit_card.expiry": "Data di scadenza",
    "payment_method.credit_card.cvc": "CVC",

    "payment_method.direct_debit.title": "Addebito diretto SEPA",
    "payment_method.direct_debit.email_address": "Indirizzo email",
    "payment_method.direct_debit.holder": "Nome del titolare",
    "payment_method.direct_debit.iban": "IBAN",
    "payment_method.direct_debit.bank_name": "Nome della banca",
    "payment_method.direct_debit.bic": "BIC",

    "payment_method.ideal.title": "iDEAL",
    "payment_method.ideal.bic": "Nome della banca",

    "payment_method.sofort.title": "Sofortüberweisung",
    "payment_method.sofort.bank_country": "Paese della banca",

    "payment_method.alipay.title": "Alipay",
    "payment_method.twint.title": "TWINT",
    "payment_method.apple_pay.title": "Apple Pay",
    "payment_method.google_pay.title": "Google Pay",

    "payment_method.giropay.title": "Giropay",
    "payment_method.giropay.holder_name": "Nome del titolare",

    "payment_method.eps.title": "EPS",
    "payment_method.eps.holder_name": "Nome del titolare",

    "payment_method.invoice.title": "Pay by Invoice",
    "payment_method.invoice.type.individual": "Private customer",
    "payment_method.invoice.type.company": "Business customer",
    "payment_method.invoice.first_name": "First Name",
    "payment_method.invoice.last_name": "Last Name",
    "payment_method.invoice.company_name": "Company Name",
    "payment_method.invoice.city": "City",
    "payment_method.invoice.zip_code": "Zip code",
    "payment_method.invoice.address": "Address",
    "payment_method.invoice.email": "Email",
    "payment_method.invoice.language": "Lingua",
    "payment_method.invoice.language.de": "Tedesco",
    "payment_method.invoice.language.fr": "Francese",
    "payment_method.invoice.region_restriction": "Pay by invoice is currently only available for residents of Switzerland. A valid Swiss mailing address is required.",

    "payment_method.crypto.title": "Pay by crypto currency",

    "payment_method.postfinance.title": "PostFinance",
    "payment_method.postfinance.auth_type": "Modalità di pagamento",
    "payment_method.postfinance.auth_type.card": "Carta / App",
    "payment_method.postfinance.auth_type.efinance": "E-Finance",
    "payment_method.postfinance.language": "Lingua",
    "payment_method.postfinance.language.de": "Tedesco",
    "payment_method.postfinance.language.fr": "Francese",
    "payment_method.postfinance.language.it": "Italiano",
    "payment_method.postfinance.language.en": "Inglese",

    "insurance.refund_protect.title": "Prenotazione rimborsabile",
    "insurance.trekksoft_standard.title": "Assicurazione sulla cancellazione",

    "insurance.description.line1": "Per non assurmerti il rischio finanziario sulla cancellazione, ti suggeriamo di completare la nostra assicurazione sui costi di cancellazione.",
    "insurance.description.line2": "Eventi assicurati",
    "insurance.description.line3": "(estratto dai termini generali dell'assicurazione)",
    "insurance.description.line4": "Puoi trovare l'esatto servizio in",
    "insurance.description.line5": "termini generali dell'assicurazione",
    "insurance.description.line6": "Ti invitiamo a contattarci per telefono o email qualora avessi delle domande",
    "insurance.coverage.line1": "Malattia, gravidanza, incidente, morte",
    "insurance.coverage.line2": "Danni su proprietà residenziale",
    "insurance.coverage.line3": "Ritardo e cancellazione del mezzo di trasporto nel corso del viaggio",
    "insurance.coverage.line4": "Scioperi o pericoli presso la destinazione",
    "insurance.coverage.line5": "Disoccupazione / inaspettato inizio di occupazione",

    "cart.title": "Il tuo carrello",
    "cart.show": "Mostra carrello",
    "cart.hide": "Nascondi carrello",

    "person.title": "Partecipante",
    "person.firstname": "Nome",
    "person.lastname": "Cognome",

    "terms.dialog.title": "Terms and Conditions",

    "cvc.help.title": "Dove posso trovare il mio numero CVV / CVC?",
    "cvc.help.maestro": "Belgian debit cards do not have a Card Verification Code (CVC). To confirm the payment, please leave it blank. /  Enter 000 or 999 to confirm payment.",

    "dialog.button.close": "Chiudi",

    "error.payment_method.no_payment_method_is_configured": "Nessun metodo di pagamento è configurato. Si prega di contattare il venditore.",
    "error.validation.invalid_value": "Valore non valido",
    "error.validation.check_input": "Sei pregato di inserire i tuoi dati",
    "error.fatal.title": "Si è verificato un errore",
    "error.fatal.description": "Ci scusiamo ma qualcosa è andato storto. Sei pregato di riprovare oppure metterti in contatto con noi.",
    "error.fatal.message": "Messaggio di errore",
    "error.payment.payment_failed": "Pagamento fallito. Sei pregato di riprovare oppure metterti in contatto con noi.",
    "error.payment.3ds_authentication_failed": "Autenticazione 3DS non riuscita. Sei pregato di riprovare oppure metterti in contatto con noi.",

    "step.title.addons": "Pagamento rimborsabile",
    "step.title.payment": "Metodo di pagamento",
    "step.title.google_pay": "Google Pay",

    "step.breadcrumb.addons": "Pagamento rimborsabile",
    "step.breadcrumb.payment": "Pagamento",
    "step.breadcrumb.google_pay": "Google Pay",

    "action.next": "Avanti",
    "action.pay": "Paga",
    "action.more": "Più informazioni",

    "action.abort.title": "Interrompere il pagamento",

    "dcc.title": "Scegli la valuta per pagare",
    "dcc.alternate_payment_method_description": "This alternative payment method only allows %currency% as currency. If you would like to pay in another currency please select another payment method.",
    "dcc.fees_info": "include tutte le tasse e gli oneri",
    "dcc.conversion_by_payyo": "%price%, conversione da parte di Payyo",
    "dcc.conversion_by_issuer": "%price%, conversione da parte dell'emittente della carta",

    "footer.line1": "Please send an email to %supportEmail% for all inquiries regarding payments.",
    "footer.line2": "© 2010 - %year% TrekkSoft AG. Made in Switzerland.",

    "countdown.title": "%time% remaining to finish order",

    "rebate.title": "Sconto",

    "refund_protect.recommended": "Consigliato",
    "refund_protect.protection_selected": "Pagamento rimborsabile",
    "refund_protect.instructions_1": "Aggiornare la prenotazione e ricevere un rimborso del 100%, in caso di cancellazione entro la data del servizio, se non si è in grado di partecipare e si può dimostrare di avere uno dei motivi definiti nei nostri Termini e condizioni.",
    "refund_protect.instructions_2": "Realizzato da",
    "refund_protect.instructions_powered_by": "Refundable.me",
    "refund_protect.reasons.covid_19": "Infezione e isolamento COVID-19",
    "refund_protect.reasons.covid_19_link": "Vedi dettagli",
    "refund_protect.reasons.reasons": "Richiesta di rimborso completo in caso di",
    "refund_protect.reasons.reason_0": "Malattia o infortunio dell'ultimo minuto (inc COVID-19)",
    "refund_protect.reasons.reason_1": "Malattia Incidente e infortunio",
    "refund_protect.reasons.reason_2": "Emergenze domestiche e familiari",
    "refund_protect.reasons.reason_3": "Mancato trasporto",
    "refund_protect.reasons.reason_4": "Furto di documenti",
    "refund_protect.reasons.reason_5": "Condizione medica preesistente",
    "refund_protect.reasons.reason_6": "Citazione in tribunale",
    "refund_protect.reasons.reason_7": "Guasto al veicolo privato",
    "refund_protect.reasons.reason_8": "Richiamo dei servizi di emergenza",
    "refund_protect.reasons.reason_9": "E molti altri",
    "refund_protect.reasons.see_more": "E altro ancora",
    "refund_protect.protection_rejected": "Termini di rimborso di base",
    "refund_protect.protection_rejected_terms": "Accetto e acconsento alla politica di rimborso standard del provider"
};
