import React from "react";
import {Fade} from "reactstrap";
import Translate from "./Translate";
import DeclineExplanation from "./DeclineExplanation";

class Error extends React.Component {
    render() {
        const message = this.props.message;
        const details = this.props.details;
        if (!message) {
            return null;
        }

        let messageEl = (<Translate>{message}</Translate>);
        if (details && details.decline_details && details.decline_details.length > 0) {
            messageEl = (<DeclineExplanation details={details.decline_details}/>);
        }

        if (this.props.isMobile) {
            return (<div className="alert alert-embarrassment">{messageEl}</div>);
        } else {
            return (
                <Fade>
                    <div className="alert alert-embarrassment d-none d-sm-block">{messageEl}</div>
                </Fade>
            );
        }
    }
}

export default Error;
