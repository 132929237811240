import Color from "color";

class StylingModel {
    constructor(styling, merchantName) {
        this.logoUrl = styling.logo_url;
        this.faviconUrl = styling.favicon_url;
        this.merchantName = merchantName;

        this.accentColor = null;
        if (styling.accent_color) {
            this.accentColor = new Color(styling.accent_color);
        }
    }
}

export default StylingModel;
