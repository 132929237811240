import React from "react";
import {Collapse, CustomInput} from 'reactstrap';
import Translate from "../Translate";
import LearnMore from "../LearnMore";
import Insurants from "./Insurants";
import PropTypes from "prop-types";
import InsuranceQuoteModel from "../../models/InsuranceQuoteModel";
import Validator from "../../library/Validator";

class TrekkSoftStandard extends React.Component {
    constructor(props) {
        super(props);

        this.onInsuranceCheck = this.onInsuranceCheck.bind(this);
        this.onInsurantsChange = this.onInsurantsChange.bind(this);
        this.validate = this.validate.bind(this);

        this.validate();
    }

    componentDidMount() {
        const {insuranceQuote, isChecked, onChange} = this.props;
        // validate untouched quite
        if (!isChecked && !Validator.isValid(insuranceQuote)) {
            Validator.markAs(insuranceQuote, true);
            onChange(false, insuranceQuote);
        }
    }

    validate() {
        const {insuranceQuote} = this.props;
        let isValid = true;
        insuranceQuote.persons.forEach(function (p) {
            if (!Validator.isValid(p)) {
                isValid = false;
            }
        });

        Validator.markAs(insuranceQuote, isValid);
    }

    onInsuranceCheck(event) {
        const {insuranceQuote, onChange} = this.props;
        onChange(event.target.checked, insuranceQuote);
    }

    onInsurantsChange(persons) {
        const {insuranceQuote, isChecked, onChange} = this.props;
        insuranceQuote.persons = persons;
        onChange(isChecked, insuranceQuote);
    }

    render() {
        const {insuranceQuote, isChecked, showValidationErrors} = this.props;
        const isInsuranceSelected = isChecked && insuranceQuote.isSelected;

        return <React.Fragment>
            <div className={"card-header" + (isInsuranceSelected === false ? " border-bottom-0" : "")}>
                <CustomInput type="checkbox" id="insurance" checked={isInsuranceSelected}
                             onChange={this.onInsuranceCheck}>
                    <label className="custom-control-label w-100" htmlFor="insurance">
                        <h3 className="h5 d-flex justify-content-between align-items-center mb-3">
                            <span><Translate>insurance.trekksoft_standard.title</Translate></span>
                            <span className="font-weight-bold">{insuranceQuote.premium.format()}</span>
                        </h3>
                    </label>
                    <div className="description">
                        <Translate>insurance.description.line1</Translate>{" "}
                        <Translate>insurance.description.line2</Translate>
                        <LearnMore>
                            {" "}
                            <Translate>insurance.description.line3</Translate>{": "}
                            <ul>
                                <li><Translate>insurance.coverage.line1</Translate></li>
                                <li><Translate>insurance.coverage.line2</Translate></li>
                                <li><Translate>insurance.coverage.line3</Translate></li>
                                <li><Translate>insurance.coverage.line4</Translate></li>
                                <li><Translate>insurance.coverage.line5</Translate></li>
                            </ul>
                            <Translate>insurance.description.line4</Translate>{" "}
                            <a href="https://s3.eu-central-1.amazonaws.com/io.trekkpay.insurance/attachments/AVB_TrekkSoft_AK_EN_def_201708.pdf"
                               target="_blank">
                                <Translate>insurance.description.line5</Translate>{" "}
                            </a>
                            <br/>
                            <Translate>insurance.description.line6</Translate>
                            {": Tel. +41 44 563 62 61, "}
                            <a href="mailto:info@helvetic-assistance.ch">info@helvetic-assistance.ch</a>
                        </LearnMore>
                    </div>
                </CustomInput>
            </div>
            <Collapse isOpen={isInsuranceSelected}>
                <Insurants insuranceQuote={insuranceQuote}
                           onChange={this.onInsurantsChange}
                           showValidationErrors={showValidationErrors}/>
            </Collapse>
        </React.Fragment>
    }
}

TrekkSoftStandard.propTypes = {
    insuranceQuote: PropTypes.instanceOf(InsuranceQuoteModel).isRequired,
    onChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool,
    showValidationErrors: PropTypes.bool,
}

export default TrekkSoftStandard;
