import React, {useState} from 'react';
import Translate from "../Translate";
import PropTypes from "prop-types";
import {Collapse} from "reactstrap";
import CovidBanner from "./CovidBanner";

function RefundReasons(props) {
    const [isExpanded, setExpanded] = useState(false);

    const toggle = (e) => {
        e.preventDefault();
        setExpanded(!isExpanded);
    }

    return <React.Fragment>
        <Collapse className={props.className} isOpen={!isExpanded}>
            <div className={"reasons-list short"}>
                <span><i className={"check"}></i><Translate>{"refund_protect.reasons.reason_0"}</Translate></span>
                <span><i className={"check"}></i><Translate>{"refund_protect.reasons.reason_2"}</Translate></span>
                <span><i className={"check"}></i><Translate>{"refund_protect.reasons.reason_3"}</Translate></span>
                <a onClick={toggle}
                   className={"refund-reason short"}><i
                    className={"check"}></i><Translate>{"refund_protect.reasons.see_more"}</Translate>...</a>
            </div>
        </Collapse>
        <Collapse className={props.className} isOpen={isExpanded}>
            <CovidBanner className={"refund-reasons-alert"}/>

            <div className={"reasons-list"}>
                <span><i className={"check"}></i><Translate>{"refund_protect.reasons.reason_1"}</Translate></span>
                <span><i className={"check"}></i><Translate>{"refund_protect.reasons.reason_2"}</Translate></span>
                <span><i className={"check"}></i><Translate>{"refund_protect.reasons.reason_3"}</Translate></span>
                <span><i className={"check"}></i><Translate>{"refund_protect.reasons.reason_4"}</Translate></span>
                <span><i className={"check"}></i><Translate>{"refund_protect.reasons.reason_5"}</Translate></span>
                <span><i className={"check"}></i><Translate>{"refund_protect.reasons.reason_6"}</Translate></span>
                <span><i className={"check"}></i><Translate>{"refund_protect.reasons.reason_7"}</Translate></span>
                <span><i className={"check"}></i><Translate>{"refund_protect.reasons.reason_8"}</Translate></span>
                <span><i className={"check"}></i><Translate>{"refund_protect.reasons.reason_9"}</Translate></span>
            </div>
        </Collapse>
    </React.Fragment>
}

RefundReasons.propTypes = {
    className: PropTypes.string,
}

RefundReasons.defaultProps = {
    className: '',
};

export default RefundReasons;
