class Steps {

    constructor() {
        this.availableSteps = [];
        this.current = null;
    }

    addStep(stepName, enabled = true) {
        if (this.getStepByName(stepName) === null) {
            this.availableSteps.push({name: stepName, enabled: enabled, index: this.availableSteps.length});
        }

        if (this.current === null) {
            this.nextStep();
        }
    }

    getStepByName (name) {
        for (let i = 0; i < this.availableSteps.length; i++) {
            let step = this.availableSteps[i];
            if (step.name === name) {
                return step;
            }
        }

        return null;
    }

    static get stepAddons() {
        return "addons";
    }

    static get stepPayment() {
        return "payment";
    }

    static get stepGooglePay() {
        return "google_pay";
    }

    static get steps() {
        return [Steps.stepPayment, Steps.stepAddons];
    };

    // do the same as setter bellow, but more consistent
    setCurrentStep(stepName) {
        this.currentStep = stepName;
    }

    /** @deprecated */
    set currentStep(stepName) {
        const step = this.getStepByName(stepName);
        if (step) {
            this.current = step;
        }
    }

    get currentStep() {
        if (this.current) {
            return this.current.name;
        }

        return null;
    }

    getAvailableSteps() {
        const available = [];
        for (let i = 0; i < this.availableSteps.length; i++) {
            let step = this.availableSteps[i];
            if (step.enabled) {
                available.push(step.name);
            }
        }

        return available;
    }

    getCurrentStepIndex() {

        if (this.current === null) {
            return 0;
        }

        return this.current.index + 1;
    }

    hasMultipleSteps() {
        return this.getAvailableSteps().length > 1;
    }

    nextStep() {
        if (this.isLastStep()) {
            return;
        }

        let ind = 0;
        if (this.current !== null) {
            ind = this.current.index;
        }

        for (let i = ind + 1; i < this.availableSteps.length; i++) {
            if (this.availableSteps[i].enabled) {
                this.current = this.availableSteps[i];
                return;
            }
        }
    }

    isLastStep(...ignore) {
        if (this.availableSteps.length === 0) {
            return true;
        }

        let ind = 0;
        if (this.current !== null) {
            ind = this.current.index + 1;
        }

        for (let i = ind; i < this.availableSteps.length; i++) {
            if (ignore.indexOf(this.availableSteps[i].name) > -1) {
                continue;
            }

            if (this.availableSteps[i].enabled) {
                return false;
            }
        }

        return true;
    }

    enableStep(stepName) {
        const step = this.getStepByName(stepName);
        if (step) {
            step.enabled = true;
        }
    }

    disableStep(stepName) {
        const step = this.getStepByName(stepName);
        if (step) {
            step.enabled = false;
        }
    }
}

export default Steps;
