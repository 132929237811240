import React from "react";
import Cart from "./Cart";
import Translate from "./Translate";
import DCC from "./Payment/DCC";
import Error from "./Error";
import DccModel from "../models/DccModel";
import {LanguageContext} from "../contexts/language";

class ActionBar extends React.Component {
    constructor(props) {
        super(props);
        this.toggleCart = this.toggleCart.bind(this);
        this.state = {isCartShown: false};
    }

    toggleCart(event) {
        event.preventDefault();
        this.setState({isCartShown: !this.state.isCartShown});
    }

    stateClasses() {
        if (this.state.isCartShown === true || this.props.dcc instanceof DccModel || this.props.error) {
            return " with-addon border-top";
        }

        return "";
    }

    render() {
        const pageLanguage = this.context;

        return (
            <React.Fragment>
                <div className="action-bar-addons d-block d-md-none">
                    {this.props.payment.isDCCPossible() && this.props.dcc &&
                        <DCC dcc={this.props.dcc} isAccepted={this.props.isDccAccepted}
                             onChange={this.props.onDccChange}
                             paymentMethod={this.props.payment.getSelectedMethod()} isMobile={true}/>
                    }
                    <Error message={this.props.error} isMobile={true}/>
                    <div className={"cart" + (this.state.isCartShown ? "" : " d-none")}>
                        <Cart cart={this.props.cart} isMobile={true}/>
                    </div>
                </div>
                <div className={"action-bar d-block d-md-none d-flex justify-content-between" + this.stateClasses()}>
                    <div className="align-self-center">
                        <p className="mb-1">
                            <strong>{this.props.cart.toPay.format()}</strong>
                        </p>
                        <a href="#" onClick={this.toggleCart}
                           title={Translate.translate(pageLanguage, this.state.isCartShown ? "cart.hide" : "cart.show")}>
                            <Translate>
                                {(this.state.isCartShown ? "cart.hide" : "cart.show")}
                            </Translate>
                        </a>
                    </div>
                    <div className="align-self-center">
                        {this.props.children}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ActionBar.contextType = LanguageContext;

export default ActionBar;
