import React from "react";
import Translate from "./Translate";
import Breadcrumbs from "./Breadcrumbs";
import StepTitle from "./StepTitle";

class Header extends React.Component {
    render() {
        const {id, steps, onStepChange} = this.props;

        return (
            <div className="p-header">
                <h1>
                    <Translate>page.title</Translate>
                </h1>
                <Breadcrumbs steps={steps} onStepChange={onStepChange}/>
                <StepTitle id={id} steps={steps}/>
            </div>
        );
    }
}

export default Header;
