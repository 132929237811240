import React from "react";
import Translate from "./Translate";
import {Collapse} from 'reactstrap';

class LearnMore extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.state = {isShown: false};
    }

    onClick(event) {
        event.preventDefault();
        this.setState({isShown: true});
    }

    render() {
        return (
            <React.Fragment>
                <Collapse isOpen={this.state.isShown} tag="span">
                    {this.props.children}
                </Collapse>
                {!this.state.isShown &&
                <React.Fragment>
                    {"... "}
                    <a href="#" onClick={this.onClick}>
                        <Translate>action.more</Translate>
                    </a>
                </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

export default LearnMore;