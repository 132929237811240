class InsuranceQuoteModel {
    constructor(insurancePlanId, premium, persons, isSelected = false) {
        this.insurancePlanId = insurancePlanId;
        /** @type{InsuranceQuotePersonModel[]} */
        this.persons = persons;
        this.premium = premium;
        this.isSelected = isSelected;
    }

    getRequestData(language) {
        return {
            insurance_plan_id: this.insurancePlanId,
            language: language,
            persons: this.persons.map((person) => person.getRequestData()),
            premium: this.premium.amount,
            is_selected: this.isSelected,
        };
    }
}

export default InsuranceQuoteModel;
