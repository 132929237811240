export default {
    "language.de": "Deutsch",
    "language.en": "English",
    "language.es": "Español",
    "language.fr": "Français",
    "language.it": "Italiano",
    "language.pt": "Português ",

    "country.au": "Australia",
    "country.at": "Austria",
    "country.be": "Belgium",
    "country.ca": "Canada",
    "country.cz": "Czech Republic",
    "country.dk": "Denmark",
    "country.ee": "Estonia",
    "country.fi": "Finland",
    "country.fr": "France",
    "country.de": "Germany",
    "country.gr": "Greece",
    "country.ie": "Ireland",
    "country.it": "Italy",
    "country.lv": "Latvia",
    "country.lt": "Lithuania",
    "country.nl": "Netherlands",
    "country.nz": "New Zealand",
    "country.no": "Norway",
    "country.pl": "Poland",
    "country.pt": "Portugal",
    "country.sk": "Slovakia",
    "country.si": "Slovenia",
    "country.es": "Spain",
    "country.se": "Sweden",
    "country.ch": "Switzerland",
    "country.gb": "United Kingdom",
    "country.us": "United States",

    "select": "Select",

    "page.title": "Checkout",

    "language_switcher.label": "Switch language of the page",
    "language_switcher.option.label": "Switch language of the page to %language%",
    "terms.button.label": "Close Terms and Conditions modal",

    "payment_method.credit_card.title": "Card",
    "payment_method.credit_card.number": "Card number",
    "payment_method.credit_card.holder": "Holder",
    "payment_method.credit_card.expiry": "Expiry date",
    "payment_method.credit_card.cvc": "CVC",

    "payment_method.direct_debit.title": "SEPA Direct Debit",
    "payment_method.direct_debit.email_address": "Email address",
    "payment_method.direct_debit.holder": "Account holder name",
    "payment_method.direct_debit.iban": "IBAN",
    "payment_method.direct_debit.bank_name": "Bank name",
    "payment_method.direct_debit.bic": "BIC",

    "payment_method.ideal.title": "iDEAL",
    "payment_method.ideal.bic": "Bank Name",

    "payment_method.sofort.title": "Sofortüberweisung",
    "payment_method.sofort.bank_country": "Bank Country",

    "payment_method.alipay.title": "Alipay",
    "payment_method.twint.title": "TWINT",
    "payment_method.apple_pay.title": "Apple Pay",
    "payment_method.google_pay.title": "Google Pay",

    "payment_method.giropay.title": "Giropay",
    "payment_method.giropay.holder_name": "Account holder full name",

    "payment_method.eps.title": "EPS",
    "payment_method.eps.holder_name": "Account holder full name",

    "payment_method.invoice.title": "Pay by Invoice",
    "payment_method.invoice.type.individual": "Private customer",
    "payment_method.invoice.type.company": "Business customer",
    "payment_method.invoice.first_name": "First Name",
    "payment_method.invoice.last_name": "Last Name",
    "payment_method.invoice.company_name": "Company Name",
    "payment_method.invoice.city": "City",
    "payment_method.invoice.zip_code": "Zip code",
    "payment_method.invoice.address": "Address",
    "payment_method.invoice.email": "Email",
    "payment_method.invoice.language": "Language",
    "payment_method.invoice.language.de": "German",
    "payment_method.invoice.language.fr": "French",
    "payment_method.invoice.region_restriction": "Pay by invoice is currently only available for residents of Switzerland. A valid Swiss mailing address is required.",

    "payment_method.crypto.title": "Pay by crypto currency",

    "payment_method.postfinance.title": "PostFinance",
    "payment_method.postfinance.auth_type": "Payment Type",
    "payment_method.postfinance.auth_type.card": "Card",
    "payment_method.postfinance.auth_type.efinance": "E-Finance",
    "payment_method.postfinance.language": "Language",
    "payment_method.postfinance.language.de": "German",
    "payment_method.postfinance.language.fr": "French",
    "payment_method.postfinance.language.it": "Italian",
    "payment_method.postfinance.language.en": "English",

    "payment_method.klarna.title": "Klarna",
    "payment_method.klarna.country": "Customer country",
    "payment_method.klarna.email_address": "Customer email address",

    "insurance.refund_protect.title": "Refundable booking",
    "insurance.trekksoft_standard.title": "Cancellation insurance",

    "insurance.description.line1": "So you do not have to bear the financial risk upon cancellation, we recommend you to complete our cancellation costs insurance.",
    "insurance.description.line2": "Insured events",
    "insurance.description.line3": "(extract from the general terms of insurance)",
    "insurance.description.line4": "You can find the exact service in ",
    "insurance.description.line5": "the general terms of insurance",
    "insurance.description.line6": "In case of any questions give us a call or write an e-mail",
    "insurance.coverage.line1": "Illness, pregnancy, accident, death",
    "insurance.coverage.line2": "Damage to residential property at home",
    "insurance.coverage.line3": "Delay and cancellation of the means of transport on the outward journey",
    "insurance.coverage.line4": "Strikes and dangers at the destination",
    "insurance.coverage.line5": "Unemployment / unexpected commencement of employment",

    "cart.title": "Your cart",
    "cart.show": "Show Cart",
    "cart.hide": "Hide Cart",

    "person.title": "Guest",
    "person.firstname": "First name",
    "person.lastname": "Last name",

    "terms.dialog.title": "Terms and Conditions",

    "cvc.help.title": "Where can I find my CVV / CVC number?",
    "cvc.help.maestro": "Belgian debit cards do not have a Card Verification Code (CVC). To confirm the payment, please leave it blank. /  Enter 000 or 999 to confirm payment.",
    "cvc.help.example_image.description": "Example of a card with highlighted CVC code on it",

    "dialog.button.close": "Close",

    "error.payment_method.no_payment_method_is_configured": "No payment method is configured. Please contact the seller.",
    "error.validation.invalid_value": "Invalid value",
    "error.validation.check_input": "Please, check your input",
    "error.fatal.title": "Something unexpected happened",
    "error.fatal.description": "Sorry, but something went wrong. Please try again or get in contact with us.",
    "error.fatal.message": "Error message",
    "error.payment.payment_failed": "Payment failed. Please try again or get in contact with us.",
    "error.payment.3ds_authentication_failed": "3DS authentication failed. Please try again or get in contact with us.",

    "step.title.addons": "Upgrade your refund terms",
    "step.title.payment": "Payment method",
    "step.title.google_pay": "Google Pay",

    "step.breadcrumb.addons": "Refundable Payment",
    "step.breadcrumb.payment": "Payment",
    "step.breadcrumb.google_pay": "Google Pay",

    "action.next": "Next",
    "action.pay": "Pay",
    "action.more": "Learn more",
    "action.abort.title": "Abort the payment",

    "dcc.title": "Choose currency to pay",
    "dcc.alternate_payment_method_description": "This alternative payment method only allows %currency% as currency. If you would like to pay in another currency please select another payment method.",
    "dcc.fees_info": "includes all fees and charges",
    "dcc.conversion_by_payyo": "%price%, conversion by Payyo",
    "dcc.conversion_by_issuer": "%price%, conversion by card issuer",

    "footer.line1": "Please contact %supportEmail% for all payment related queries.",
    "footer.line2": "© 2010 - %year% TrekkSoft AG. Made in Switzerland.",

    "countdown.title": "%time% remaining to finish the payment",

    "rebate.title": "Rebate",

    "refund_protect.recommended": "Recommended",
    "refund_protect.protection_selected": "Refundable Payment",
    "refund_protect.instructions_1": "Upgrade your booking and receive a 100% refund, in case of cancellation by the service date, if you are unable to attend and can show evidence for any of the reasons defined in our T&C.",
    "refund_protect.instructions_2": "Powered by",
    "refund_protect.instructions_powered_by": "Refundable.me",
    "refund_protect.reasons.covid_19": "COVID-19 infection and isolation",
    "refund_protect.reasons.covid_19_link": "See details",
    "refund_protect.reasons.reasons": "Request full refund in case of",
    "refund_protect.reasons.reason_0": "Last Minute Sickness and Injury (inc COVID-19)",
    "refund_protect.reasons.reason_1": "Sickness and Injury",
    "refund_protect.reasons.reason_2": "Home and Family Emergencies",
    "refund_protect.reasons.reason_3": "Transport Failure",
    "refund_protect.reasons.reason_4": "Theft of Documents",
    "refund_protect.reasons.reason_5": "Pre-existing medical condition",
    "refund_protect.reasons.reason_6": "Court Summons",
    "refund_protect.reasons.reason_7": "Private Vehicle Failure",
    "refund_protect.reasons.reason_8": "Emergency Services Recall",
    "refund_protect.reasons.reason_9": "And many more",
    "refund_protect.reasons.see_more": "And more",
    "refund_protect.protection_rejected": "Basic Refund Terms",
    "refund_protect.protection_rejected_terms": "I accept and agree to the standard refund policy of the provider",
};
