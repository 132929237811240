import React from "react";
import PaymentModel from "../models/PaymentModel";
import Translate from "./Translate";
import ExtraCharges from "./ExtraCharges";
import InsuranceQuote from "./InsuranceQuote";
import Rebate from "./Rebate";

class Cart extends React.Component {
    render() {
        /** @type {CartModel} */
        const cart = this.props.cart;

        let payment = null;
        if (cart.payment instanceof PaymentModel) {
            payment = (
                <div className="d-flex flex-wrap justify-content-between">
                    <p className="mb-2 mb-sm-0 pr-1">{cart.payment.description}</p>
                    {cart.showPrices() && <p className="mb-2 mb-sm-0 font-weight-bold text-nowrap">
                        {cart.payment.price.format()}
                    </p>}
                </div>
            );
        }

        if (this.props.isMobile) {
            return (
                <React.Fragment>
                    {payment}
                    <InsuranceQuote insuranceQuote={cart.insuranceQuote} showPrices={cart.showPrices()} showSeparator={false}/>
                    <ExtraCharges extraCharges={cart.extraCharges} showPrices={cart.showPrices()} showSeparator={false}/>
                    <Rebate rebate={cart.rebate} showPrices={cart.showPrices()} showSeparator={false}/>
                </React.Fragment>
            );
        } else {
            return (
                <div className="card mb-3 d-none d-md-block">
                    <div className="card-body py-3">
                        <h3 className="h5 card-title d-flex flex-wrap justify-content-between align-items-center mb-0 py-1">
                            <span><Translate>cart.title</Translate></span>
                            <span className="font-weight-bold text-nowrap">{cart.total.format()}</span>
                        </h3>
                    </div>
                    <div className="card-footer">
                        {payment}
                        <InsuranceQuote insuranceQuote={cart.insuranceQuote} showPrices={cart.showPrices()} showSeparator={true}/>
                        <ExtraCharges extraCharges={cart.extraCharges} showPrices={cart.showPrices()} showSeparator={true}/>
                        <Rebate rebate={cart.rebate} showPrices={cart.showPrices()} showSeparator={true}/>
                    </div>
                </div>
            );
        }
    }
}

export default Cart;
