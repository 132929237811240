const assertSameCurrency = function (left, right) {
    if (left.currency !== right.currency) throw new Error("Different currencies");
};

const assertType = function (that) {
    if (!(that instanceof Money)) throw new TypeError("Instance of Money required");
};

class Money {
    constructor(amount, currency, decimalDigits) {
        this.amount = amount;
        this.currency = currency;
        this.decimalDigits = decimalDigits;
    }

    getAmount() {
        return this.amount;
    }

    getCurrency() {
        return this.currency;
    }

    add(that) {
        assertType(that);
        assertSameCurrency(this, that);

        return new Money(this.amount + that.amount, this.currency, this.decimalDigits);
    }

    subtract(that) {
        assertType(that);
        assertSameCurrency(this, that);

        return new Money(this.amount - that.amount, this.currency, this.decimalDigits);
    }

    negate() {
        return new Money(-1 * this.amount, this.currency, this.decimalDigits);
    }

    format() {
        return this.currency + " " + this.formatWithoutCurrency()
    }

    formatWithoutCurrency() {
        return (this.amount / Math.pow(10, this.decimalDigits)).toFixed(this.decimalDigits);
    }
}

export default Money
