class InsuranceQuotePersonModel {
    constructor(reference, firstName, lastName) {
        this.reference = reference;
        this.firstName = firstName;
        this.lastName = lastName;
    }

    getRequestData() {
        return {
            "reference": this.reference,
            "first_name": this.firstName,
            "last_name": this.lastName
        }
    }
}

export default InsuranceQuotePersonModel;
