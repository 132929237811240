import React from "react";
import {FormFeedback, Input, Label} from 'reactstrap';
import Validator from "../../../library/Validator";
import Translate from "../../Translate";
import PropTypes from "prop-types";

class DirectDebit extends React.Component {

    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleCustomerEmailAddress = this.handleCustomerEmailAddress.bind(this);
        this.handleHolderName = this.handleHolderName.bind(this);
        this.handleHolderIban = this.handleHolderIban.bind(this);
        this.handleBankName = this.handleBankName.bind(this);
        this.handleBankBic = this.handleBankBic.bind(this);

        const {data, customer} = this.props;

        customer.email = customer.email !== undefined ? customer.email : '';

        data.holder = data.holder !== undefined ? data.holder : {name: '', iban: ''};
        data.bank = data.bank !== undefined ? data.bank : {name: '', bic: ''};
    }

    validate() {
        const {data, customer} = this.props;

        let isValid = true;
        if (!this.isValidCustomerEmailAddress(customer.email) ||
            !this.isValidHolderName(data.holder.name) ||
            !this.isValidHolderIban(data.holder.iban) ||
            !this.isValidBankName(data.bank.name) ||
            !this.isValidBankBic(data.bank.bic)
        ) {
            isValid = false;
        }

        Validator.markAs(data, isValid);

        this.props.validate();
    }

    onChange() {
        this.validate();
        this.props.onChange();
    }

    handleHolderName(event) {
        this.props.data.holder.name = event.target.value;
        this.onChange();
    }

    handleCustomerEmailAddress(event) {
        if (this.isCustomerEmailAddressRequired()) {
            this.props.customer.email = event.target.value;
            this.onChange();
        }
    }

    handleHolderIban(event) {
        this.props.data.holder.iban = event.target.value.replace(/\s/g, "").toUpperCase();
        this.onChange();
    }

    handleBankName(event) {
        this.props.data.bank.name = event.target.value;
        this.onChange();
    }

    handleBankBic(event) {
        this.props.data.bank.bic = event.target.value.replace(/\s/g, "").toUpperCase();
        this.onChange();
    }

    isValidCustomerEmailAddress(emailAddress) {
        if (!this.isCustomerEmailAddressRequired()) {
            return true;
        }

        return emailAddress.trim().length > 0;
    }

    isValidHolderName(name) {
        return name.trim().length > 0;
    }

    isValidHolderIban(iban) {
        return /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/.test(iban);
    }

    isValidBankName(name) {
        return name.trim().length > 0;
    }

    isValidBankBic(bic) {
        return /^[a-zA-Z0-9]{8,11}$/.test(bic);
    }

    isCustomerEmailAddressRequired() {
        return this.props.extraFields.includes('customer_email_address');
    }

    render() {
        const {data, customer, showValidationErrors} = this.props;
        let emailField = null;

        if (this.isCustomerEmailAddressRequired()) {
            emailField = (
                <div className="col-md-12 mb-3">
                    <Label for="dd-customer-email" className="required">
                        <Translate>payment_method.direct_debit.email_address</Translate>
                    </Label>
                    <Input type="email"
                           id="dd-customer-email"
                           autoComplete="off"
                           placeholder="janedoe@example.com"
                           value={customer.email}
                           onChange={this.handleCustomerEmailAddress}
                           invalid={showValidationErrors && !this.isValidCustomerEmailAddress(customer.email)}/>
                    <FormFeedback>
                        <Translate>error.validation.invalid_value</Translate>
                    </FormFeedback>
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <Label for="dd-holder-name" className="required">
                            <Translate>payment_method.direct_debit.holder</Translate>
                        </Label>
                        <Input type="text"
                               id="dd-holder-name"
                               autoComplete="off"
                               placeholder="Jane Doe"
                               value={data.holder.name}
                               onChange={this.handleHolderName}
                               invalid={showValidationErrors && !this.isValidHolderName(data.holder.name)}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>

                    {emailField}

                    <div className="col-md-12 mb-3">
                        <Label for="dd-holder-iban" className="required">
                            <Translate>payment_method.direct_debit.iban</Translate>
                        </Label>
                        <Input type="text"
                               id="dd-holder-iban"
                               autoComplete="off"
                               placeholder="DE89370400440532013000"
                               value={data.holder.iban}
                               onChange={this.handleHolderIban}
                               invalid={showValidationErrors && !this.isValidHolderIban(data.holder.iban)}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                    <div className="col-md-12 mb-3">
                        <Label for="dd-bank-name" className="required">
                            <Translate>payment_method.direct_debit.bank_name</Translate>
                        </Label>
                        <Input type="text"
                               id="dd-bank-name"
                               autoComplete="off"
                               placeholder="European Central Bank"
                               value={data.bank.name}
                               onChange={this.handleBankName}
                               invalid={showValidationErrors && !this.isValidBankName(data.bank.name)}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                    <div className="col-md-12">
                        <Label for="dd-bank-bic" className="required">
                            <Translate>payment_method.direct_debit.bic</Translate>
                        </Label>
                        <Input type="text"
                               id="dd-bank-bic"
                               autoComplete="off"
                               placeholder="DABAIE2D"
                               value={data.bank.bic}
                               onChange={this.handleBankBic}
                               invalid={showValidationErrors && !this.isValidBankBic(data.bank.bic)}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

DirectDebit.propTypes = {
    data: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    extraFields: PropTypes.array.isRequired,
    validate: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    showValidationErrors: PropTypes.bool.isRequired,
};

export default DirectDebit;
