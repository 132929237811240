let stripePromise = null;

function loadStripe(key) {
    if (stripePromise === null) {
        stripePromise = import(/* webpackChunkName: "stripe" */ "@stripe/stripe-js")
            .then(stripe => stripe.loadStripe(key, {"apiVersion": "2022-11-15"}))
            .catch(e => null); // ignore error
    }

    return stripePromise;
}

export {loadStripe};
