import React from "react";
import ActionButton from "./ActionButton";
import Translate from "../Translate";
import {LanguageContext} from "../../contexts/language";

class NextButton extends React.Component {
    render() {
        const pageLanguage = this.context;

        const btn = (
            <ActionButton isLoading={this.props.isLoading}
                          onClick={this.props.onAction}
                          ariaLabel={Translate.translate(pageLanguage, "action.next")}>
                <Translate>action.next</Translate>
            </ActionButton>
        );

        if (this.props.isMobile) {
            return btn;
        } else {
            return (
                <div className="d-flex flex-row align-items-center justify-content-end">
                    <div className="d-none d-md-block">
                        {btn}
                    </div>
                </div>
            );
        }
    }
}

NextButton.contextType = LanguageContext;

export default NextButton;
