import React from 'react';
import Translate from "../Translate";
import PropTypes from "prop-types";

const REFUND_PROTECT_HOME_PAGE_URL = "https://www.refundable.me/";

function RefundInstructions(props) {
    return <div className={props.className}>
        <p><Translate>{"refund_protect.instructions_1"}</Translate></p>
        <p><Translate>{"refund_protect.instructions_2"}</Translate>{" "}
            <a target={"_blank"} href={REFUND_PROTECT_HOME_PAGE_URL}>
                <Translate>{"refund_protect.instructions_powered_by"}</Translate>
            </a>
        </p>
    </div>
}

RefundInstructions.propTypes = {
    className: PropTypes.string,
}

RefundInstructions.defaultProps = {
    className: '',
};

export default RefundInstructions;
