import React from "react";
import {fallbackLanguage, LanguageContext, strings} from '../contexts/language';

class Translate extends React.Component {

    static translate(language, string, placeholders = {}) {
        return Translate.replacePlaceholders(language, Translate.findTranslation(language, string), placeholders);
    }

    static replacePlaceholders(language, string, placeholders = {}) {
        Object.keys(placeholders).forEach((placeholder) => {
            if (placeholder !== "children") {
                const re = new RegExp("%" + placeholder + "%", "g");
                string = string.replace(re, Translate.findTranslation(language, placeholders[placeholder]));
            }
        });

        return string;
    }

    static findTranslation(language, string) {
        if (strings.hasOwnProperty(language)) {
            if (strings[language].hasOwnProperty(string)) {
                return strings[language][string];
            }
        }

        if (strings[fallbackLanguage].hasOwnProperty(string)) {
            return strings[fallbackLanguage][string];
        }

        return string;
    }

    render() {
        return (
            <LanguageContext.Consumer>
                {language => Translate.translate(language, this.props.children, this.props)}
            </LanguageContext.Consumer>
        );
    }
}

export default Translate;

export const WithLanguageContext = (Component) => {
    return (props) => (
        <LanguageContext.Consumer>
            {language => <Component {...props}
                                    language={language}
                                    t={(string, placeholders) => Translate.translate(language, string, placeholders)}/>}
        </LanguageContext.Consumer>
    );
};
