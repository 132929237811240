import "./index.scss";
import React from "react";
import ReactDOM from "react-dom";
import HPP from "./components/HPP";

const nativeFetch = window.fetch;

window.fetchActive = 0;
window.fetch = (() => {
    const inc = () => window.fetchActive++;
    const dec = () => window.fetchActive--;

    return (...args) => {
        const req = nativeFetch.apply(undefined, args);

        inc();
        req.then(dec, dec);

        return req;
    };
})();

window['PayyoHPP'] = window['PayyoHPP'] || (() => {
    return {
        initialize: (containerId, pageId, options) => {
            options = options || {};
            ReactDOM.render(
                <HPP pageId={pageId}
                     endpoint={options.endpoint}
                     ptpMode={options.ptpMode}
                     ptpFieldUri={options.ptpFieldUri}
                     mode={options.mode}/>,
                document.getElementById(containerId)
            );
        }
    };
})();
