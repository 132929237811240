import React from "react";
import Translate from "./Translate";

class Breadcrumbs extends React.Component {
    constructor(props) {
        super(props);
        this.setStep = this.setStep.bind(this);
    }

    setStep(step, event) {
        event.preventDefault();
        this.props.steps.currentStep = step;
        this.props.onStepChange();
    }

    isCurrentStep(step) {
        return this.props.steps.currentStep === step;
    }

    render() {
        const steps = this.props.steps.getAvailableSteps();
        if (steps.length <= 1) {
            return null;
        }

        return (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {steps.map((step) =>
                        <li key={step} className={"breadcrumb-item" + (this.isCurrentStep(step) ? " active" : "")}>
                            <span onClick={(e) => this.setStep(step, e)}>
                                <Translate>
                                    {"step.breadcrumb." + step}
                                </Translate>
                            </span>
                        </li>
                    )}
                </ol>
            </nav>
        );
    }
}

export default Breadcrumbs;
