import React from "react";
import {Collapse, CustomInput} from 'reactstrap';
import Translate, {WithLanguageContext} from "../Translate";
import Schemes from "./Schemes";

class Method extends React.Component {
    constructor(props) {
        super(props);
        this.onChoose = this.onChoose.bind(this);
    }

    onChoose() {
        const {method, onChoose} = this.props;
        onChoose(method.type);
    }

    render() {
        const {payment, method, children, t} = this.props;
        const isChecked = payment.selectedMethodType === method.type;
        const showOptions = isChecked && !!React.Children.count(children);
        const controlLabel = "payment_method." + method.type + ".title";
        const controlId ="payment-method-" + method.type;
        const labelId = "payment-method-label-" + method.type;
        const paymentPanelId = "payment-method-form-panel-" + method.type;

        return (
            <React.Fragment>
                <div
                    onClick={this.onChoose}
                    className={"payment-method list-group-item list-group-item-action d-flex justify-content-between" + (showOptions ? " chosen" : "")}
                    role={"button"}
                    aria-labelledby={labelId}>

                    <CustomInput
                        id={controlId}
                        name={"paymentMethod"}
                        type={"radio"}
                        checked={isChecked}
                        onChange={this.onChoose}
                        value={method.type}
                        aria-labelledby={labelId}
                        aria-controls={paymentPanelId}
                        aria-checked={isChecked ? "true" : "false"}>

                        <label id={labelId}
                               className="custom-control-label"
                               htmlFor={controlId}
                               aria-label={t(controlLabel)}>

                            <h3 className="h5 mb-0">
                                <Translate>{controlLabel}</Translate>
                            </h3>
                        </label>
                    </CustomInput>
                    <Schemes method={method}/>
                </div>
                <Collapse id={paymentPanelId}
                          isOpen={showOptions}
                          className={"list-group-item payment-form-container active"}
                          aria-labelledby={labelId}
                          role={"tabpanel"}>

                    <div className={"payment-form" + (showOptions ? " chosen" : "")}>
                        {children}
                    </div>
                </Collapse>
            </React.Fragment>
        );
    }
}

export default WithLanguageContext(Method);

export function withoutAvailabilityCheck(WrappedComponent) {
    return WrappedComponent;
}
