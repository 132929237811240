export const ga = {
    actions: {
        loadingStarted: "hpp.loading.started",
        loadingSuccess: "hpp.loading.success",
        loadingFailed: "hpp.loading.failed",
        paymentAttemptStarted: "hpp.payment_attempt.started",
        paymentAttemptFailed: "hpp.payment_attempt.failed",
        paymentInitiationStarted: "hpp.payment_initiation.started",
        paymentInitiationSuccess: "hpp.payment_initiation.success",
        paymentInitiationFailed: "hpp.payment_initiation.failed",
        paymentCheckStarted: "hpp.payment_check.started",
        paymentDirectAuthorizationSuccess: "hpp.payment_direct_authorization.success",
    },
    categories: {
        system: "system",
        user: "user",
    }
}

let paymentPageId = null;

export class GoogleAnalytics {

    static setPaymentPageId(id) {
        paymentPageId = id;
    }

    static event(action, parameters) {
        Object.assign(parameters, {"payment_page_id": paymentPageId});
        if (window.gtag) {
            gtag("event", action, parameters);
        }
    }

    static systemEvent(action, parameters = {}) {
        Object.assign(parameters, {"event_category": ga.categories.system, "non_interaction": true});

        this.event(action, parameters);
    }

    static userEvent(action, parameters = {}) {
        Object.assign(parameters, {"event_category": ga.categories.user});

        this.event(action, parameters);
    }
}
