import React from "react";

class Schemes extends React.Component {
    render() {
        const method = this.props.method;
        const hasScheme = method.data.scheme !== undefined && method.data.scheme !== "unknown";
        const schemes = method.schemes.map((scheme) =>
            <div key={method.type + " " + scheme}
                 className={"badge-payment-method-type " + method.type + " " + scheme}/>
        );
        if (schemes.length === 0) {
            schemes.push(<div key={method.type} className={"badge-payment-method-type " + method.type}/>);
        }

        return (
            <div className="d-flex justify-content-end">
                {hasScheme && <div className={"badge-payment-method-type " + method.type + " " + method.data.scheme}/>}
                {!hasScheme && schemes}
            </div>
        );
    }
}

export default Schemes;
