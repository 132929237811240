import React from "react";
import Translate from "./Translate";

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <hr/>
                <div className="row">
                    <div className="col-md-8">
                        <div className="px-3 pt-4 pb-4">
                            <div className="px-3 pt-3 pb-3">
                                <Translate supportEmail={this.props.supportEmail}>
                                    footer.line1
                                </Translate>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="px-3 pt-md-4 pb-4">
                            <div className="px-3 pt-md-3 pb-3">
                                <div className="pb-2">
                                    <div className="pb-1">
                                        <div className="badge-trekksoft"/>
                                    </div>
                                </div>
                                <Translate year={new Date().getFullYear()}>footer.line2</Translate>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
