import React from "react";
import {CustomInput, FormFeedback, Label} from 'reactstrap';
import Validator from "../../../library/Validator";
import Translate from "../../Translate";
import {LanguageContext} from "../../../contexts/language";

class Sofort extends React.Component {

    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleBankCountryUpdate = this.handleBankCountryUpdate.bind(this);

        if (this.props.data.bank === undefined) {
            this.props.data.bank = {country_code: ''};
        }

        this.validate();
    }

    validate() {
        const data = this.props.data;

        let isValid = true;
        if (!this.isValidBankCountry(data.bank.country_code)) {
            isValid = false;
        }

        Validator.markAs(data, isValid);

        this.props.validate();
    }

    onChange() {
        this.validate();
        this.props.onChange();
    }

    handleBankCountryUpdate(event) {
        if (this.isBankCountryCodeRequired()) {
            this.props.data.bank.country_code = event.target.value;
            this.onChange();
        }
    }

    isValidBankCountry(countryCode) {
        if (!this.isBankCountryCodeRequired()) {
            return true;
        }

        return countryCode.length === 2;
    }

    isBankCountryCodeRequired() {
        return this.props.extraFields.includes('bank_country_code')
    }

    render() {
        const {data, showValidationErrors} = this.props;
        const pageLanguage = this.context;

        if (!this.isBankCountryCodeRequired()) {
            return null;
        }

        return (<React.Fragment key={'sofort'}>
            <div className="row">
                <div className="col-md-12 mb-3">
                    <Label for="sofort-bank-country-code" className="required">
                        <Translate>payment_method.sofort.bank_country</Translate>
                    </Label>
                    <CustomInput type="select"
                                 id="sofort-bank-country-code"
                                 autoComplete="off"
                                 value={data.bank.country_code}
                                 onChange={this.handleBankCountryUpdate}
                                 invalid={showValidationErrors && !this.isValidBankCountry(data.bank.country_code)}>
                        <option className="placeholder" value="" label={Translate.translate(pageLanguage, "select")}/>
                        <option value="at" label={Translate.translate(pageLanguage, "country.at")}/>
                        <option value="be" label={Translate.translate(pageLanguage, "country.be")}/>
                        <option value="de" label={Translate.translate(pageLanguage, "country.de")}/>
                        <option value="es" label={Translate.translate(pageLanguage, "country.es")}/>
                        <option value="nl" label={Translate.translate(pageLanguage, "country.nl")}/>
                    </CustomInput>
                    <FormFeedback>
                        <Translate>error.validation.invalid_value</Translate>
                    </FormFeedback>
                </div>
            </div>
        </React.Fragment>);
    }
}

Sofort.contextType = LanguageContext;

export default Sofort;
