import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Cart from "./Cart";
import CartModel from "../models/CartModel";
import Money from "../library/Money";
import LoadersCounter from "../library/LoadersCounter";
import PaymentModel from "../models/PaymentModel";
import InsuranceQuoteModel from "../models/InsuranceQuoteModel";
import InsuranceQuotePersonModel from "../models/InsuranceQuotePersonModel";
import Payment from "./Payment";
import Steps from "../models/Steps";
import Addons from "./Addons";
import AddonsModel from "../models/AddonsModel";
import PaymentMethod from "../models/PaymentMethod";
import DccModel from "../models/DccModel";
import {LanguageContext} from "../contexts/language";
import Validator from "../library/Validator";
import Loader from "./Loader";
import Error from "./Error";
import StylingModel from "../models/StylingModel";
import Styling from "./Styling";
import Panel from "./Panel";
import ReturnUrls from "../models/ReturnUrls";
import ActionBar from "./ActionBar";
import PayButton from "./Buttons/PayButton";
import NextButton from "./Buttons/NextButton";
import TermsModel from "../models/TermsModel";
import EventHandler from "../library/EventHandler"
import {encodeQueryData, getParameterByName} from "../library/UrlHelper";
import DCC from "./Payment/DCC";
import Terms from "./Payment/Terms";
import ExtraChargesModel from "../models/ExtraChargesModel";
import {ga, GoogleAnalytics} from "../library/GoogleAnalytics";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import McpModel from "../models/McpModel";
import RebateModel from "../models/RebateModel";
import PaymentValidationError from "../library/PaymentValidationError";
import Translate from "./Translate";
import ErrorHandler from "../library/ErrorHandler";

// Hack for Safari to make it reload
// the page after back button was pressed
window.addEventListener("pageshow", (event) => {
    if (event.persisted) {
        window.location.reload();
    }
});

__webpack_public_path__ = (new URL(document.currentScript.src)).origin + __webpack_public_path__;

const IIN_LENGTH = 8;

class HPP extends React.Component {
    constructor(props) {
        super(props);
        this.nextStep = this.nextStep.bind(this);
        this.onPageClose = this.onPageClose.bind(this);
        this.onStepChange = this.onStepChange.bind(this);
        this.onAddonsChange = this.onAddonsChange.bind(this);
        this.onAddonsSubmit = this.onAddonsSubmit.bind(this);
        this.onInsuranceChange = this.onInsuranceChange.bind(this);
        this.onPaymentChange = this.onPaymentChange.bind(this);
        this.onPaymentChoose = this.onPaymentChoose.bind(this);
        this.onPaymentSubmit = this.onPaymentSubmit.bind(this);
        this.onDccTokenChange = this.onDccTokenChange.bind(this);
        this.onDccChange = this.onDccChange.bind(this);
        this.onToggleTerms = this.onToggleTerms.bind(this);
        this.onLanguageChange = this.onLanguageChange.bind(this);
        this.areTermsOfServiceAccepted = this.areTermsOfServiceAccepted.bind(this);
        this.onValidate = this.onValidate.bind(this);
        this.isInsuranceQuoteValid = this.isInsuranceQuoteValid.bind(this);
        this.endpoint = window.location.origin + "/";
        if (this.props.endpoint) {
            this.endpoint = (this.props.endpoint + "/").replace(/\/\/$/, "/");
        }

        this.state = {
            isPageLoading: true,
            isLoading: false,
            isPaying: false,
            isValidating: false,
            addons: new AddonsModel(),
            cart: new CartModel(),
            steps: new Steps(),
            showAddonsValidationErrors: false,
            showPaymentValidationErrors: false,
            dcc: null,
            fatalError: null,
            error: null,
            error_details: null,
            loadersCounter: new LoadersCounter(),
            eventHandler: new EventHandler(this.props.mode),
            expiresIn: null,
            dccOptions: {type: "", token: null, amount: 0},
        };
    }

    componentDidMount() {
        GoogleAnalytics.setPaymentPageId(this.props.pageId);
        GoogleAnalytics.systemEvent(ga.actions.loadingStarted);

        const that = this;
        const eventHandler = this.state.eventHandler;

        fetch(
            this.endpoint + "hpp/" + this.props.pageId + '/data',
            {
                cache: "no-store"
            }
        )
            .then(response => {
                return response.clone().json().catch(e => {
                    response.text().then(text => {
                        Rollbar.critical(e, {status: response.status, body: text});
                    })
                })
            })
            .then(
                (response) => {
                    if (response.success !== true) {
                        if (response.url) {
                            eventHandler.success(response.transaction_id, response.url);
                        } else {
                            GoogleAnalytics.systemEvent(ga.actions.loadingFailed);
                            that.errorFatal(response.error);
                        }
                        return;
                    }

                    that.parsePage(response.data);

                    const cart = that.state.cart;
                    cart.payment = that.state.payment;
                    cart.extraCharges = that.state.extraCharges;
                    that.setState({cart: cart});

                    that.state.steps.addStep(Steps.stepPayment);
                    that.state.steps.setCurrentStep(Steps.stepPayment);
                    if (that.state.insuranceQuote instanceof InsuranceQuoteModel) {
                        that.state.steps.addStep(Steps.stepAddons);
                    }
                    that.setState({steps: that.state.steps});

                    const selectedMethod = cart.payment.getSelectedMethod();
                    if (selectedMethod !== null) {
                        that.onPaymentChoose(selectedMethod.type);
                    }

                    that.checkPreviousAttempt();

                    GoogleAnalytics.systemEvent(ga.actions.loadingSuccess);
                }
            )
            .catch(
                (error) => {
                    // ignore Fetch request aborted
                    if (error.name !== "AbortError") {
                        GoogleAnalytics.systemEvent(ga.actions.loadingFailed);
                        ErrorHandler.logCritical(error);
                    }

                    that.errorFatal(error);
                }
            )
            .finally(() => that.setState({isPageLoading: false}));
    }

    checkPreviousAttempt() {
        const that = this;
        const prevAttemptTransactionStatus = getParameterByName("transaction_status");

        if (prevAttemptTransactionStatus !== "error") {
            return;
        }

        const errorCode = getParameterByName("error_code");
        const errorMessage = getParameterByName("error");
        if (errorMessage) {
            return that.errorShow(errorMessage + (errorCode ? ` (${errorCode})` : ""));
        }

        const prevAttemptTransactionId = getParameterByName("transaction_id");
        const defaultErrorMessage = that.getDefaultDeclineError();

        if (prevAttemptTransactionId === null) {
            return that.errorShow(defaultErrorMessage);
        }

        fetch(this.endpoint + "declines/" + prevAttemptTransactionId + "/latest")
            .then(response => response.json())
            .then(
                (response) => {
                    let errorMessage = defaultErrorMessage;
                    if (response.success === true && response.decline) {
                        if (response.decline.error) {
                            const error = response.decline.error;
                            errorMessage = `${error.name} (${error.code}): ${error.description}`;
                        } else {
                            errorMessage = that.getDefaultDeclineError(response.decline.funding_instrument_type)
                        }
                    }

                    that.errorShow(errorMessage);
                }
            )
            .catch(
                (error) => {
                    // ignore Fetch request aborted
                    if (error.name !== "AbortError") {
                        ErrorHandler.logCritical(error);
                    }

                    that.errorShow(defaultErrorMessage);
                }
            );
    }

    getDefaultDeclineError(fundingInstrumentType = null) {
        if (fundingInstrumentType === "credit_card" || fundingInstrumentType === "card") {
            return "error.payment.3ds_authentication_failed"
        }

        return "error.payment.payment_failed";
    }

    errorHide() {
        this.setState({error: null, error_details: null});
    }

    errorShow(message = null, details = null) {
        this.setState({error: message || "error.validation.check_input", error_details: details});
    }

    errorFatal(message) {
        this.setState({fatalError: message});
    }

    areTermsOfServiceAccepted() {
        const {terms} = this.state;

        if (terms instanceof TermsModel) {
            if (!terms.areTermsAccepted) {
                this.errorShow();
                return false;
            }
        }

        return true;
    }

    pay() {
        GoogleAnalytics.userEvent(ga.actions.paymentAttemptStarted);

        /** @type {CartModel} */
        const {
            cart,
            cart: {insuranceQuote},
            terms,
            steps,
            eventHandler,
            dcc,
            addons,
            language,
            insuranceQuote: insuranceQuoteOffer
        } = this.state;
        const requestData = {};

        if (this.areTermsOfServiceAccepted()) {
            requestData["terms"] = terms.areTermsAccepted;
        } else {
            GoogleAnalytics.userEvent(ga.actions.paymentAttemptFailed);
            return Promise.reject(new PaymentValidationError("Terms of service are not accepted"));
        }

        if (insuranceQuoteOffer && this.isInsuranceQuoteValid(addons, insuranceQuoteOffer, insuranceQuote)) {
            requestData["insurance"] = insuranceQuote.getRequestData(language);
        } else if (insuranceQuoteOffer instanceof InsuranceQuoteModel) {
            GoogleAnalytics.userEvent(ga.actions.paymentAttemptFailed);
            this.state.steps.currentStep = Steps.stepAddons;
            this.onStepChange();
            this.onAddonsSubmit();
            return Promise.reject(new PaymentValidationError("Invalid insurance quote"));
        }

        requestData["dcc"] = {"was_dcc_offered": dcc instanceof DccModel};
        if (cart.dcc instanceof DccModel) {
            requestData["dcc"] = cart.dcc.getRequestData();
            requestData["dcc"]["was_dcc_offered"] = true;
        }

        let paymentData = Promise.resolve(null);
        requestData["payment"] = {};

        if (cart.payment instanceof PaymentModel) {
            if (!Validator.isValid(cart.payment)) {
                GoogleAnalytics.userEvent(ga.actions.paymentAttemptFailed);
                steps.currentStep = Steps.stepPayment;
                this.onStepChange();
                this.onPaymentSubmit();
                return Promise.reject(new PaymentValidationError("Invalid payment data"));
            }
            paymentData = cart.payment.getRequestData();
        }

        this.setState({isPaying: true});
        this.errorHide();

        const that = this;

        const success = (value) => {
            GoogleAnalytics.userEvent(ga.actions.paymentInitiationSuccess, {"payment_method": requestData.payment.type});
            GoogleAnalytics.systemEvent(value.is_third_party_redirect ? ga.actions.paymentCheckStarted : ga.actions.paymentDirectAuthorizationSuccess);
            eventHandler.success(value.transaction_id, value.url);
            return value;
        }

        const failure = (reason) => {
            const gaEvent = reason.google_analytics_event || ga.actions.paymentInitiationFailed;
            GoogleAnalytics.userEvent(gaEvent, {"payment_method": requestData.payment.type});

            if (reason.redirect_url) {
                this.state.eventHandler.close(reason.redirect_url);

                return reason;
            }

            that.setState({isPaying: false});
            that.errorShow(reason.error, reason.details);

            return reason;
        };

        return paymentData
            .then((pd) => {
                if (pd) {
                    requestData["payment"] = pd;
                }

                GoogleAnalytics.userEvent(ga.actions.paymentInitiationStarted, {"payment_method": requestData.payment.type});

                return requestData;
            })
            .then((rd) => {
                return fetch(
                    this.endpoint + "hpp/" + this.props.pageId,
                    {
                        method: "POST",
                        body: JSON.stringify(rd),
                        cache: "no-store",
                        headers: {
                            "Content-Type": "application/json",
                            "X-Requested-With": "XMLHttpRequest"
                        },
                    }
                );
            })
            .then(response => response.json())
            .then(
                (response) => {
                    if (response.success) {
                        return success(response);
                    }
                    return failure(response);
                }
            )
            .catch(
                (error) => {
                    // ignore Fetch request aborted
                    if (error.name !== "AbortError") {
                        ErrorHandler.logCritical(error);
                    }

                    return failure(error);
                }
            );
    }

    onDccTokenChange() {
        const {payment, cart, dccOptions} = this.state,
            sm = payment ? payment.getSelectedMethod() : null,
            cartTotal = cart.total.getAmount(),
            callback = (newType, newToken, newAmount) => {
                const {type, token, amount} = this.state.dccOptions;
                return newType === type && newToken === token && newAmount === amount;
            };

        if (sm === null) {
            return;
        }

        let token = true;

        if (sm.type === PaymentMethod.typeCreditCard) {
            token = false; // reset token
            const cartToken = (sm.data.iin || sm.data.number || "").substring(0, IIN_LENGTH);
            if (cartToken.length < IIN_LENGTH) {
                this.onDccChange(false, true);
            } else {
                token = cartToken;
            }
        }

        if (sm.type === PaymentMethod.typeDirectDebit && sm.data.holder) {
            token = false; // reset token
            const directDebitToken = sm.data.holder.iban.substring(0, 2);
            if (directDebitToken.length === 2) {
                token = directDebitToken;
            }
        }

        if (sm.type === PaymentMethod.typeKlarna) {
            token = false; // reset token
            const klarnaToken = (sm.data.country_code || "").substring(0, 2);
            if (klarnaToken.length === 2) {
                token = klarnaToken;
            }
        }

        if ([
            PaymentMethod.typeEps,
            PaymentMethod.typeGiropay,
            PaymentMethod.typeIDeal,
            PaymentMethod.typeInvoice,
            PaymentMethod.typePostFinance,
            PaymentMethod.typeSofort,
            PaymentMethod.typeTwint,
        ].indexOf(sm.type) !== -1) {
            token = true;
        }

        if (token && (dccOptions.token !== token || dccOptions.type !== sm.type || dccOptions.amount !== cartTotal)) {
            if (payment && payment.isDCCPossible() && (sm instanceof PaymentMethod)) {
                this.loadDccQuote(sm.type, token, callback);
            }
        }

        sm.token = token;
        this.setState({dccOptions: {type: sm.type, token: token, amount: cartTotal}});
    }

    loadDccQuote(type, token, callback) {
        this.state.loadersCounter.increase();
        this.setState({isLoading: this.state.loadersCounter.isLoading()});
        const that = this;

        if (this.controllerDccQuote) {
            this.controllerDccQuote.abort();
        }

        this.controllerDccQuote = new AbortController();

        fetch(
            this.endpoint + "dcc-quote/" + this.props.pageId,
            {
                method: "post",
                signal: this.controllerDccQuote.signal,
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    type: type,
                    token: token,
                    amount: this.state.cart.total.getAmount(),
                    currency: this.state.cart.total.getCurrency()
                })
            }
        )
            .then(response => response.json())
            .then(
                (response) => {
                    let dcc = null;
                    if (response.success && callback(type, token, that.state.cart.total.getAmount())) {
                        dcc = new DccModel(response);
                    }
                    that.setState({dcc: dcc});
                    that.onDccChange(true);
                }
            )
            .catch(
                (error) => {
                    // ignore Fetch request aborted
                    if (error.name !== "AbortError") {
                        ErrorHandler.logCritical(error);
                    }

                    that.setState({dcc: null});
                    that.onDccChange(false);
                }
            )
            .finally(() => {
                that.loadTerms();
                that.state.loadersCounter.decrease();
                that.setState({isLoading: that.state.loadersCounter.isLoading()});
                that.controllerDccQuote = null;
            });
    }

    loadTerms() {
        const that = this;
        const sm = this.state.payment.getSelectedMethod();
        const currency = this.state.cart.toPay.getCurrency();
        const amount = this.state.cart.toPay.getAmount();
        const terms = this.state.terms;

        if (!sm || !sm instanceof PaymentMethod) {
            return; // Payment Method is not chosen
        }

        if (terms.paymentMethodType === sm.type && terms.paymentMethodToken === sm.token && terms.currency === currency && terms.amount === amount) {
            return; // nothing changed
        }

        if (!sm.token) {
            terms.reset();
            return; // token is not set
        }

        this.state.loadersCounter.increase();
        this.setState({isLoading: this.state.loadersCounter.isLoading()});

        terms.areTermsAccepted = false;
        terms.paymentMethodType = sm.type;
        terms.paymentMethodToken = sm.token;
        terms.currency = currency;
        terms.amount = amount;
        terms.isReady = false;
        this.setState({terms: terms});

        if (this.controllerTerms) {
            this.controllerTerms.abort();
        }

        this.controllerTerms = new AbortController();

        fetch(
            this.endpoint + "terms?" + encodeQueryData({
                merchantId: terms.merchantId,
                paymentMethodType: terms.paymentMethodType,
                paymentMethodToken: terms.paymentMethodToken,
                currency: terms.currency,
                amount: terms.amount,
                language: this.state.language,
                softDescriptor: terms.softDescriptor
            }),
            {
                method: "get",
                signal: this.controllerTerms.signal
            }
        )
            .then(response => response.json())
            .then(
                (response) => {
                    sm.options = {...sm.options, ...response.funding_instrument.options};

                    terms.acceptanceText = response.acceptance_text;
                    terms.fullText = response.full_text;
                    terms.isReady = true;
                    that.setState({terms: terms});
                }
            )
            .catch(
                (error) => {
                    // ignore Fetch request aborted
                    if (error.name !== "AbortError") {
                        ErrorHandler.logCritical(error);
                    }
                }
            )
            .finally(
                () => {
                    that.state.loadersCounter.decrease();
                    that.setState({isLoading: that.state.loadersCounter.isLoading()});
                }
            );
    }

    onDccChange(isAccepted, invalidateDcc = false) {
        const {cart, payment} = this.state;
        let {dcc} = this.state;
        if (invalidateDcc) {
            dcc = null;
        }

        if (isAccepted && payment.isDCCPossible()) {
            cart.dcc = this.state.dcc;
        } else {
            cart.dcc = null;
        }

        this.setState({dcc, cart});
        this.loadTerms();
    }

    parsePage(data) {
        const cart = this.state.cart;

        if (data.mcp) {
            cart.mcp = new McpModel(data.mcp);
        }
        if (data.rebate_amount) {
            cart.rebate = new RebateModel(data.rebate_amount, data.currency, data.minor_currency_digits);
        }

        let insuranceQuote = null;
        if (data.insurance_quote) {
            const quote = data.insurance_quote;
            insuranceQuote = new InsuranceQuoteModel(
                quote.insurance_plan_id,
                new Money(quote.price, data.currency, data.minor_currency_digits),
                quote.persons.map((person) => new InsuranceQuotePersonModel(
                    person.reference,
                    person.first_name,
                    person.last_name
                ))
            );
        }

        const paymentMethods = [];
        data.payment_methods.forEach((pm) => {
            paymentMethods.push(new PaymentMethod(pm.type, pm.schemes, pm.options));
        });

        if (paymentMethods.length === 0) {
            this.errorShow('error.payment_method.no_payment_method_is_configured');
        }

        this.setState({
            cart: cart,
            payment: new PaymentModel(
                new Money(data.amount, data.currency, data.minor_currency_digits),
                paymentMethods,
                data.description
            ),
            styling: new StylingModel(data.styling, data.merchant_name),
            returnUrls: new ReturnUrls(data.return_urls),
            terms: new TermsModel(data.merchant_id, data.billing_descriptor),
            expiresIn: data.expires_in,
            supportEmail: data.support_email,
            extraCharges: new ExtraChargesModel(data.extra_charges, data.currency, data.minor_currency_digits),
            insuranceQuote: insuranceQuote
        });
        this.onLanguageChange(data.language);
    }

    nextStep() {
        this.state.steps.nextStep();
        this.onStepChange();
    }

    onStepChange() {
        this.setState({steps: this.state.steps});
        window.scrollTo({top: 0, behavior: "smooth"});
    }

    onPageClose() {
        this.state.eventHandler.close(
            this.state.returnUrls instanceof ReturnUrls ? this.state.returnUrls.abort : null
        );
    }

    onAddonsChange(addonType, checked, addon) {
        const {addons, cart, payment} = this.state;

        switch (addonType) {
            case AddonsModel.addonInsurance:
                addons.add(addonType)
                addon.isSelected = checked;
                cart.insuranceQuote = addon;
                break;
            default:
                checked ? addons.add(addonType) : addons.remove(addonType);
        }

        this.setState({addons, cart, payment});
        this.onDccTokenChange();
        this.errorHide();
    }

    onAddonsSubmit() {
        const {addons, steps, cart: {insuranceQuote}, insuranceQuote: insuranceQuoteOffer} = this.state;

        this.setState({showAddonsValidationErrors: true});

        if (!this.isInsuranceQuoteValid(addons, insuranceQuoteOffer, insuranceQuote)) {
            this.errorShow();
            return;
        }

        this.errorHide();

        if (steps.isLastStep()) {
            return this.pay().catch(function (error) {
                if (!(error instanceof PaymentValidationError)) {
                    throw (error instanceof Error) ? error : new Error(error);
                }
            });
        }

        this.nextStep();
    }

    // if insurance quote is available the quote have to be explicitly selected
    // and added to the cart with `isSelected` set to `false` or `true`, and to be valid
    isInsuranceQuoteValid(addons, offer, quote) {
        if (!offer instanceof InsuranceQuoteModel) {
            return true; // no offer
        }

        return addons.isSelected(AddonsModel.addonInsurance) && Validator.isValid(quote);
    }

    onInsuranceChange() {
        this.setState({insuranceQuote: this.state.insuranceQuote, cart: this.state.cart});
        this.errorHide();
    }

    onPaymentChange() {
        this.onDccTokenChange();
        this.setState({payment: this.state.payment});
        this.errorHide();
    }

    onPaymentChoose(type) {
        const {cart, payment, steps} = this.state;

        if (type !== undefined) {
            payment.selectedMethodType = type;
        }

        if (payment.isAddonsBuyingPossible()) {
            steps.enableStep(Steps.stepAddons);
        } else {
            steps.disableStep(Steps.stepAddons);
        }

        if (!payment.selectedMethodType) {
            steps.setCurrentStep(Steps.stepPayment);
        }

        cart.dcc = null;
        this.setState({
            showPaymentValidationErrors: false,
            payment: payment,
            cart: cart,
            steps: steps,
            dcc: null
        });
        this.onDccTokenChange();
        this.loadTerms();
        this.errorHide();
    }

    onPaymentSubmit() {
        const {payment, steps} = this.state;
        this.setState({showPaymentValidationErrors: true});
        if (!Validator.isValid(payment)) {
            this.errorShow();
            return;
        }

        this.errorHide();

        if (steps.isLastStep()) {
            return this.pay().catch(function (error) {
                if (!(error instanceof PaymentValidationError)) {
                    throw (error instanceof Error) ? error : new Error(error);
                }
            });
        }

        this.state.loadersCounter.increase();
        this.setState({isLoading: this.state.loadersCounter.isLoading()});

        return payment.updateRequestData()
            .then(() => {
                this.state.loadersCounter.decrease();
                this.setState({isLoading: this.state.loadersCounter.isLoading()});
                this.nextStep()
            });
    }

    getAddonsStepFragment(labelBy) {
        const {insuranceQuote, showAddonsValidationErrors, addons} = this.state;

        return (
            <Addons insuranceQuote={insuranceQuote}
                    showValidationErrors={showAddonsValidationErrors}
                    addons={addons}
                    areaLabelBy={labelBy}
                    onInsuranceChange={this.onInsuranceChange}
                    onChange={this.onAddonsChange}
            />
        );
    }

    getPaymentStepFragment(labelBy) {
        return (
            <Payment payment={this.state.payment}
                     onChange={this.onPaymentChange}
                     onValidate={this.onValidate}
                     onChoose={this.onPaymentChoose}
                     areaLabelBy={labelBy}
                     ptpMode={this.props.ptpMode}
                     ptpFieldUri={this.props.ptpFieldUri}
                     showValidationErrors={this.state.showPaymentValidationErrors}
            />
        );
    }

    getStepActionButton(isMobile, isFinalStep, action) {
        const {isLoading, isPaying, isValidating} = this.state;
        const showButtonAsLoading = isLoading || isPaying || isValidating;

        if (isFinalStep) {
            const {payment, cart} = this.state;

            return (<PayButton methodType={payment.selectedMethodType}
                               isLoading={showButtonAsLoading}
                               isDisabled={!payment.isMethodSelected()}
                               isMobile={isMobile}
                               onAction={action}
                               amount={cart.toPay.format()}
            />);
        }

        return (<NextButton onAction={action} isMobile={isMobile} isLoading={showButtonAsLoading}/>);
    }

    onValidate(isValidating) {
        this.setState({isValidating: isValidating});
    }

    getActionButton(isMobile) {
        const {steps} = this.state;
        const isFinal = steps.isLastStep();

        switch (steps.currentStep) {
            case Steps.stepAddons:
                return this.getStepActionButton(isMobile, isFinal, this.onAddonsSubmit);
            case Steps.stepPayment:
                return this.getStepActionButton(isMobile, isFinal, this.onPaymentSubmit);
        }

        return null;
    }

    getCurrentStepFragment(labelBy) {
        const {steps, payment, dcc, cart, terms, showPaymentValidationErrors, showAddonsValidationErrors} = this.state;
        let stepFragment, dccFragment, termsFragment = null;
        let showValidationErrors = false;

        switch (steps.currentStep) {
            case Steps.stepAddons:
                stepFragment = this.getAddonsStepFragment(labelBy);
                showValidationErrors = showAddonsValidationErrors;
                break;
            case Steps.stepPayment:
                stepFragment = this.getPaymentStepFragment(labelBy);
                showValidationErrors = showPaymentValidationErrors;
                break;
        }

        if (steps.isLastStep() && payment && payment.isDCCPossible() && dcc) {
            dccFragment = (
                <div className="list-group mb-3" key={"dcc-quote"}>

                    <DCC dcc={dcc}
                         isAccepted={cart.dcc instanceof DccModel}
                         onChange={this.onDccChange}
                         paymentMethod={payment.getSelectedMethod()}/>

                </div>
            );
        }

        if (steps.isLastStep() && terms) {
            termsFragment = (
                <Terms terms={terms}
                       onChange={this.onToggleTerms}
                       showValidationErrors={showValidationErrors}/>
            );
        }

        return (
            <React.Fragment>
                {stepFragment}
                {dccFragment}
                {termsFragment}
            </React.Fragment>
        );
    }

    onToggleTerms() {
        this.setState({terms: this.state.terms});
    }

    onLanguageChange(language) {
        this.setState({language: language});
        const {payment, styling} = this.state;
        document.documentElement.lang = language;
        document.title = [
            Translate.translate(language, "page.title"),
            payment ? payment.description : null,
            styling ? styling.merchantName : null
        ].filter(v => v).join(' - ');
    }

    render() {
        const isDccAccepted = this.state.cart.dcc instanceof DccModel;
        const stepHeaderId = 'step-area-label';

        return (
            <LanguageContext.Provider value={this.state.language}>
                <Loader isLoading={this.state.isPageLoading} fatalError={this.state.fatalError} mode={this.props.mode}
                        onPageClose={this.onPageClose}>
                    <Panel onPageClose={this.onPageClose} styling={this.state.styling} countdown={this.state.expiresIn}
                           language={this.state.language} onLanguageChange={this.onLanguageChange}/>
                    <Styling styling={this.state.styling}/>
                    <div className="container">
                        <Header steps={this.state.steps} id={stepHeaderId} onStepChange={this.onStepChange}/>
                        <div className="row">
                            <div className="col-md-8 order-1 order-sm-0">
                                {this.getCurrentStepFragment(stepHeaderId)}
                                <Error message={this.state.error} details={this.state.error_details}/>
                                {this.getActionButton()}
                            </div>
                            <div className="col-md-4 order-0 order-sm-1">
                                <Cart cart={this.state.cart}/>
                            </div>
                        </div>
                        <Footer supportEmail={this.state.supportEmail}/>
                    </div>
                    <ActionBar cart={this.state.cart}
                               payment={this.state.payment}
                               isDccAccepted={isDccAccepted}
                               onDccChange={this.onDccChange}
                               dcc={this.state.dcc}
                               error={this.state.error}>
                        {this.getActionButton(true)}
                    </ActionBar>
                </Loader>
            </LanguageContext.Provider>
        );
    }
}

export default HPP;
