import React from "react";
import de from "./languages/de";
import en from "./languages/en";
import it from "./languages/it";
import es from "./languages/es";
import fr from "./languages/fr";
import pt from "./languages/pt";

export const fallbackLanguage = "en";
export const strings = {de, en, it, es, fr, pt};
export const LanguageContext = React.createContext(fallbackLanguage);
