import React from 'react';
import TermsModal from '../Modals/TermsModal';
import {Collapse, CustomInput} from 'reactstrap';

class Terms extends React.Component {

    constructor(props) {
        super(props);
        this.onTermsShow = this.onTermsShow.bind(this);
        this.onTermsHide = this.onTermsHide.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {areTermsShown: false};
    }

    onTermsShow(event) {
        if (event.target.tagName !== 'A') {
            return;
        }

        event.preventDefault();

        if (this.isRawFullText()) {
            this.setState({areTermsShown: true});
        } else {
            window.open(this.getFullTextContent(), '_blank')
        }
    }

    onTermsHide() {
        this.setState({areTermsShown: false});
    }

    onChange(event) {
        this.props.terms.areTermsAccepted = event.target.checked ? 1 : 0;
        this.props.onChange()
    }

    render() {
        /** @type {TermsModel} */
        const {terms, showValidationErrors} = this.props;

        return (
            <Collapse isOpen={terms.isReady} tag="div" className="terms">
                <CustomInput id={"cc-terms"}
                             type={"checkbox"}
                             onChange={this.onChange}
                             checked={terms.areTermsAccepted}
                             invalid={showValidationErrors && terms.areTermsAccepted !== 1}
                             aria-required={"true"}
                             aria-checked={terms.areTermsAccepted ? "true" : "false"}>

                    <label className="custom-control-label description" htmlFor="cc-terms" onClick={this.onTermsShow}
                           dangerouslySetInnerHTML={{__html: terms.acceptanceText}}/>
                </CustomInput>
                {this.renderTermsModal()}
            </Collapse>
        );
    }

    renderTermsModal() {
        if (this.isRawFullText()) {
            const text = this.getFullTextContent()
            return <TermsModal text={this.getFullTextContent()} isOpen={this.state.areTermsShown} onHide={this.onTermsHide}/>;
        }

        return null;
    }

    isRawFullText() {
        /** @type {TermsModel} */
        const terms = this.props.terms;
        return terms.fullText && (terms.fullText.type === 'text' || terms.fullText.type === undefined); // `undefined` check for BC
    }

    getFullTextContent() {
        /** @type {TermsModel} */
        const terms = this.props.terms;
        if (terms.fullText && terms.fullText.content) {
            return terms.fullText.content;
        }

        return terms.fullText;
    }
}

export default Terms;
