import React from "react";
import {CustomInput, FormFeedback, Input, Label} from 'reactstrap';
import Validator from "../../../library/Validator";
import Translate from "../../Translate";
import {LanguageContext} from "../../../contexts/language";

class Klarna extends React.Component {
    countries = [
        "au", // Australia
        "at", // Austria
        "be", // Belgium
        "ca", // Canada
        "cz", // Czech Republic
        "dk", // Denmark
        "ee", // Estonia
        "fi", // Finland
        "fr", // France
        "de", // Germany
        "gr", // Greece
        "ie", // Ireland
        "it", // Italy
        "lv", // Latvia
        "lt", // Lithuania
        "nl", // Netherlands
        "nz", // New Zealand
        "no", // Norway
        "pl", // Poland
        "pt", // Portugal
        "sk", // Slovakia
        "si", // Slovenia
        "es", // Spain
        "se", // Sweden
        "ch", // Switzerland
        "gb", // United Kingdom
        "us"  // United States
    ];

    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleCustomerEmailAddress = this.handleCustomerEmailAddress.bind(this);
        this.handleCountryUpdate = this.handleCountryUpdate.bind(this);

        const {data, customer} = this.props;

        customer.email = customer.email !== undefined ? customer.email : '';
        data.country_code = data.country_code !== undefined ? data.country_code : '';

        this.validate();
    }

    validate() {
        const {data, customer} = this.props;

        let isValid = true;
        if (!this.isValidCountry(data.country_code)) {
            isValid = false;
        }
        if (!this.isValidCustomerEmailAddress(customer.email)) {
            isValid = false;
        }

        Validator.markAs(data, isValid);

        this.props.validate();
    }

    isValidCountry(countryCode) {
        return countryCode.length === 2;
    }

    isValidCustomerEmailAddress(emailAddress) {
        return emailAddress.trim().length > 0;
    }

    onChange() {
        this.validate();
        this.props.onChange();
    }

    handleCountryUpdate(event) {
        this.props.data.country_code = event.target.value;
        this.onChange();
    }

    handleCustomerEmailAddress(event) {
        this.props.customer.email = event.target.value;
        this.onChange();
    }

    render() {
        const {data, customer, showValidationErrors} = this.props;
        const pageLanguage = this.context;

        return (<React.Fragment>
            <div className="row">
                <div className="col-md-12 mb-3">
                    <Label for="klarna-customer-email" className="required">
                        <Translate>payment_method.klarna.email_address</Translate>
                    </Label>
                    <Input type="email"
                           id="klarna-customer-email"
                           autoComplete="off"
                           placeholder="janedoe@example.com"
                           value={customer.email}
                           onChange={this.handleCustomerEmailAddress}
                           invalid={showValidationErrors && !this.isValidCustomerEmailAddress(customer.email)}/>
                    <FormFeedback>
                        <Translate>error.validation.invalid_value</Translate>
                    </FormFeedback>
                </div>
                <div className="col-md-12 mb-3">
                    <Label for="klarna-country-code" className="required">
                        <Translate>payment_method.klarna.country</Translate>
                    </Label>
                    <CustomInput type="select"
                                 id="klarna-country-code"
                                 autoComplete="off"
                                 value={data.country_code}
                                 onChange={this.handleCountryUpdate}
                                 invalid={showValidationErrors && !this.isValidCountry(data.country_code)}>
                        <option className="placeholder" value="" label={Translate.translate(pageLanguage, "select")}/>
                        {this.countries.map(countryCode => (
                            <option key={countryCode} value={countryCode} label={Translate.translate(pageLanguage, `country.${countryCode}`)}/>
                        ))}
                    </CustomInput>
                    <FormFeedback>
                        <Translate>error.validation.invalid_value</Translate>
                    </FormFeedback>
                </div>
            </div>
        </React.Fragment>);
    }
}

Klarna.contextType = LanguageContext;

export default Klarna;
