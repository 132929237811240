import React, {useState} from 'react';
import Translate from "../Translate";
import {CustomInput} from "reactstrap";
import RefundInstructions from "./RefundInstructions";
import PropTypes from "prop-types";
import InsuranceQuoteModel from "../../models/InsuranceQuoteModel";
import Validator from "../../library/Validator";
import RefundReasons from "./RefundResons";

function RefundWidget(props) {

    const {insuranceQuote, onChange, isChecked, showValidationErrors} = props;
    const [isSelected, setSelected] = useState(isChecked ? insuranceQuote.isSelected : null);

    const onSelect = (val) => {
        setSelected(val);
        Validator.markAs(insuranceQuote, true);
        onChange(val === true, insuranceQuote);
    }

    return <div className={"refund-protect-wrapper"}>
        <div className={"refund-protect-selected"}>
            <div className={"refund-protect-option"}>
                <CustomInput id={"refund-protect-selected"} name={"selected"}
                             type="radio"
                             checked={isSelected === true}
                             className={"option-control"}
                             autoComplete={"off"}
                             invalid={showValidationErrors && (!isChecked || !Validator.isValid(insuranceQuote))}
                             onChange={() => onSelect(true)}>
                    <label className="custom-control-label" htmlFor={"refund-protect-selected"}>
                        <h3 className="h5 mb-0">
                            <Translate>{"refund_protect.protection_selected"}</Translate>
                        </h3>
                        <span className={"recommended-badge"}><Translate>{"refund_protect.recommended"}</Translate></span>
                        <span className={"refund-protect-price"}>{insuranceQuote.premium.format()}</span>
                    </label>
                </CustomInput>

                <RefundInstructions className={"refund-instructions"}/>

                <RefundReasons className={"refund-reasons"}/>

            </div>
        </div>
        <div className={"refund-protect-rejected"}>
            <div className={"refund-protect-option"}>
                <CustomInput id={"refund-protect-rejected"} name={"rejected"}
                             type="radio"
                             checked={isSelected === false}
                             className={"option-control"}
                             autoComplete={"off"}
                             invalid={showValidationErrors && (!isChecked || !Validator.isValid(insuranceQuote))}
                             onChange={() => onSelect(false)}>
                    <label className="custom-control-label" htmlFor={"refund-protect-rejected"}>
                        <h3 className="h5 mb-0">
                            <Translate>{"refund_protect.protection_rejected"}</Translate>
                        </h3>
                    </label>
                </CustomInput>

                <div className={props.className}>
                    <Translate>{"refund_protect.protection_rejected_terms"}</Translate>
                </div>
            </div>
        </div>
    </div>;
}

RefundWidget.propTypes = {
    insuranceQuote: PropTypes.instanceOf(InsuranceQuoteModel).isRequired,
    showValidationErrors: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool,
}

RefundWidget.defaultProps = {
    isChecked: false,
    showValidationErrors: false,
};

export default RefundWidget;
