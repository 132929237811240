import React from "react";
import Cleave from "cleave.js/react"
import {FormFeedback, Input, Label} from "reactstrap";
import Translate from "../../Translate";
import CvcHelp from "../../Modals/CvcHelp";
import PtpForm from "@payyo/ptp-js";
import PropTypes from 'prop-types';
import Validator from "../../../library/Validator";

const SECURE_FIELD_CC_NUMBER = 'cardNumber';
const SECURE_FIELD_CC_CVC = 'cvc';

class Card extends React.Component {

    constructor(props) {
        super(props);
        this.onNumberAndCvcChange = this.onNumberAndCvcChange.bind(this);
        this.onHolderAndExpiryChange = this.onHolderAndExpiryChange.bind(this);
        this.validate = this.validate.bind(this);
        this.handleHolder = this.handleHolder.bind(this);
        this.handleExpiryChange = this.handleExpiryChange.bind(this);
        this.handleExpiryPaste = this.handleExpiryPaste.bind(this);
        this.showCvcHelp = this.showCvcHelp.bind(this);
        this.onCvcHelpHide = this.onCvcHelpHide.bind(this);
        this.onNumberInit = this.onNumberInit.bind(this);
        this.onExpiryInit = this.onExpiryInit.bind(this);

        const d = this.props.data;
        d.number = d.number !== undefined ? d.number : "";
        d.iin = d.iin !== undefined ? d.iin : "";
        d.holder = d.holder !== undefined ? d.holder : "";
        d.expires = d.expires !== undefined ? d.expires : "";
        d.cvc = d.cvc !== undefined ? d.cvc : "";

        Object.defineProperty(d, "_expires", {enumerable: false, writable: true});
        d._expires = d._expires !== undefined ? d._expires : "";

        this.state = {
            inputFocused: null,
            isCvcHelpShown: false,
            expiryCleave: null,
            validity: {
                number: false,
                cvc: false,
                holder: this.isValidHolder(d.holder),
                expiry: this.isValidExpiry(d.expires),
            }
        };

        const {ptpMode, ptpFieldUri, debug, inputStyles, inputPlaceholderStyles} = this.props;

        this.form = PtpForm({mode: ptpMode, fieldUrl: ptpFieldUri, debug: debug});
        this.form.onReady(() => {
            this.form.setStyle(SECURE_FIELD_CC_NUMBER, 'input', inputStyles)
            this.form.setStyle(SECURE_FIELD_CC_NUMBER, 'input::placeholder', inputPlaceholderStyles)
            this.form.setStyle(SECURE_FIELD_CC_CVC, 'input', inputStyles)
            this.form.setStyle(SECURE_FIELD_CC_CVC, 'input::placeholder', inputPlaceholderStyles)
        });

        this.form.onChange((res) => {
            this.onNumberAndCvcChange(res.params.iin, res.params.scheme);
        });

        this.form.onFocus((res) => {
            this.setState({inputFocused: res.fieldName});
        });

        this.form.onBlur(() => {
            this.setState({inputFocused: null});
        });

        d.dataLoader = () => {
            if (!this.mounted) {
                return Promise.resolve({});
            }
            return this.form
                .submit()
                .then(
                    (res) => Promise.resolve({
                        number: res.card_number,
                        cvc: res.cvc
                    }),
                    (err) => Promise.reject({
                        error: err.error_details,
                        details: null
                    })
                );
        }
    }

    onNumberAndCvcChange(iin, scheme) {
        const {data, onChange, validate} = this.props;

        if (iin) {
            data.number = iin + '0'.repeat(16 - iin.length);
            data.iin = iin;
        }

        if (scheme) {
            data.scheme = scheme;
        }

        Validator.appendResult(
            data,
            this.form
                .validate()
                .then((res) => {
                    return this.validate({
                        number: res.fields.cardNumber,
                        cvc: res.fields.cvc,
                    });
                })
        );

        validate();
        onChange();
    }

    onHolderAndExpiryChange() {
        const {data, onChange, validate} = this.props;
        const res = this.validate({
            expiry: this.isValidExpiry(data._expires),
            holder: this.isValidHolder(data.holder),
        });

        Validator.appendResult(data, Promise.resolve(res));

        validate();
        onChange();
    }

    validate(props) {
        const validity = {...this.state.validity, ...props};
        this.setState({validity: validity});

        let isValid = true;
        for (let [, v] of Object.entries(validity)) {
            isValid = isValid && v;
        }

        return isValid;
    }

    componentDidMount() {
        this.form.init({
            cardNumber: {
                container: "card-number-container",
                placeholder: "1234 1234 1234 1234",
                title: "Secure input for card number"
            },
            cvc: {
                container: "cvc-container",
                placeholder: "123",
                title: "Secure input for card cvc"
            }
        });
        this.mounted = true;
    }

    componentWillUnmount() {
        this.form.destroy();
        this.mounted = false;
    }

    handleHolder(event) {
        this.props.data.holder = event.target.value;
        this.onHolderAndExpiryChange();
    }

    onExpiryInit(cleave) {
        // see https://github.com/nosir/cleave.js/issues/601#issuecomment-902747682
        cleave.lastInputValue = '';

        this.setState({expiryCleave: cleave});
    }

    handleExpiryPaste(event) {
        try {
            let month = "", year = "",
                value = event.clipboardData.getData("text/plain").replace(/[^\d]/g, "");

            if (value.length === 3 || value.length === 5) { // MYY => MMYY, MYYYY => MMYYYY
                value = "0" + value;
            }

            if (value.length === 4 || value.length === 6) { // MMYY, MMYYYY
                month = value.substr(0, 2);
                year = value.substr(-2);
            }

            if (month.length > 1 && year.length > 1) {
                this.state.expiryCleave.setRawValue(month + year);
            }
            event.preventDefault();
        } catch (e) {
            // this try-catch is for clipboardData.getData (it's supported not by all browsers)
        }
    }

    handleExpiryChange(event) {
        let value = event.target.rawValue;
        let expires = "";
        if (value.length > 0) {
            const parts = value.match(/.{1,2}/g);
            if (parts.length > 1) {
                const mm = ("00" + parts[0].substr(-2)).substr(-2);
                const yy = "20" + ("00" + parts[1].substr(-2)).substr(-2);
                expires = yy + "-" + mm;
            }
        }

        this.props.data.expires = expires;
        this.props.data._expires = value;
        this.onHolderAndExpiryChange();
    }

    isValidHolder(holder) {
        return holder.trim().length > 0;
    }

    isValidExpiry(expiry) {
        return expiry.trim().length >= 4;
    }

    showCvcHelp() {
        this.setState({isCvcHelpShown: true});
    }

    onCvcHelpHide() {
        this.setState({isCvcHelpShown: false});
    }

    onNumberInit(cleave) {
        // see https://github.com/nosir/cleave.js/issues/601#issuecomment-902747682
        cleave.lastInputValue = '';
    }

    render() {
        const {inputFocused} = this.state;
        const {data, showValidationErrors} = this.props;
        const createFocusHandler = (element) => (event) => {
            event.preventDefault();
            this.form.focus(element)
        };

        const isCardNumberInvalid = showValidationErrors && !this.state.validity.number;
        const isHolderInvalid = showValidationErrors && !this.isValidHolder(data.holder);
        const isExpiryInvalid = showValidationErrors && !this.isValidExpiry(data._expires);
        const isCvcInvalid = showValidationErrors && !this.state.validity.cvc;

        const cardNumberFocused = inputFocused === SECURE_FIELD_CC_NUMBER;
        const cvcFocused = inputFocused === SECURE_FIELD_CC_CVC;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <Label for="cc-number"
                               className="required"
                               onClick={createFocusHandler(SECURE_FIELD_CC_NUMBER)}>

                            <Translate>payment_method.credit_card.number</Translate>
                        </Label>
                        <div className={`input-addons ${isCardNumberInvalid ? 'is-invalid' : ''}`}>
                            <div
                                id={"card-number-container"}
                                className={`form-control form-control-container ${cardNumberFocused && 'focused'} ${isCardNumberInvalid ? 'is-invalid' : ''}`}
                                aria-required={"true"}
                                aria-invalid={isHolderInvalid ? "true" : "false"}/>
                            <span className="input-addon lock"/>
                        </div>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <Label for="cc-holder" className="required">
                            <Translate>payment_method.credit_card.holder</Translate>
                        </Label>
                        <Input id={"cc-holder"}
                               type={"text"}
                               autoComplete={"cc-name"}
                               placeholder={"Jane Doe"}
                               value={data.holder}
                               onChange={this.handleHolder}
                               invalid={isHolderInvalid}
                               aria-required={"true"}
                               aria-invalid={isHolderInvalid ? "true" : "false"}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <Label for="cc-expiry" className="required">
                            <Translate>payment_method.credit_card.expiry</Translate>
                        </Label>
                        <Cleave id={"cc-expiry"}
                                placeholder={"MM/YY"}
                                className={`form-control ${isExpiryInvalid ? 'is-invalid' : ''}`}
                                autoComplete={"cc-exp"}
                                onChange={this.handleExpiryChange}
                                onPaste={this.handleExpiryPaste}
                                onInit={this.onExpiryInit}
                                options={{date: true, datePattern: ["m", "y"]}}
                                maxLength={"5"}
                                value={data._expires}
                                aria-required={"true"}
                                aria-invalid={isExpiryInvalid ? "true" : "false"}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Label for="cc-cvc"
                               className="required"
                               onClick={createFocusHandler(SECURE_FIELD_CC_CVC)}>
                            <Translate>payment_method.credit_card.cvc</Translate>
                        </Label>
                        <div className={`input-addons ${isCvcInvalid ? 'is-invalid' : ''}`}>
                            <div
                                id={"cvc-container"}
                                className={`form-control form-control-container ${cvcFocused && 'focused'} ${isCvcInvalid ? 'is-invalid' : ''}`}
                                aria-required={"true"}
                                aria-invalid={isCvcInvalid ? "true" : "false"}/>
                            <span className="input-addon help" onClick={this.showCvcHelp}/>
                        </div>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                </div>
                <CvcHelp isOpen={this.state.isCvcHelpShown} onHide={this.onCvcHelpHide}/>
            </React.Fragment>
        );
    }
}

Card.propTypes = {
    ptpMode: PropTypes.oneOf(['test', 'live']).isRequired,
    debug: PropTypes.bool,
    inputStyles: PropTypes.object,
    inputPlaceholderColor: PropTypes.string,
}

Card.defaultProps = {
    debug: false,
    inputStyles: {
        'color': '#20446b;',
        'opacity': '1',
        'font-size': '.9375rem',
        'font-weight': '400'
    },
    inputPlaceholderStyles: {
        'color': '#c3d0e0',
        'font-family': 'Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
        'opacity': 1
    }
};

export default Card;
