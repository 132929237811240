class LoadersCounter {

    constructor() {
        this.count = 0;
    }

    increase() {
        this.count += 1;
    }

    decrease() {
        this.count -= 1;

        if (this.count < 0) {
            this.count = 0;
        }
    }

    isLoading() {
        return !!this.count;
    }
}

export default LoadersCounter