import React from "react";
import {CustomInput, FormFeedback, Input, Label} from "reactstrap";
import Validator from "../../../library/Validator";
import Translate from "../../Translate";
import {LanguageContext} from "../../../contexts/language";

class Invoice extends React.Component {

    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleValue = this.handleValue.bind(this);

        const data = this.props.data;

        ["_invoice_type", "_company_name"]
            .forEach(property => Object.defineProperty(data, property, {enumerable: false, writable: true}));
        ["first_name", "last_name", "company_name", "_company_name", "email", "city", "zip_code", "address1", "address2"]
            .forEach(property => data[property] = data[property] !== undefined ? data[property] : "");

        data.language = data.language !== undefined ? data.language : "de";
        data._invoice_type = data._invoice_type !== undefined ? data._invoice_type : "individual";
    }

    componentDidMount() {
        const pageLanguage = this.context;
        if (pageLanguage === "de" || pageLanguage === "fr") {
            this.props.data.language = pageLanguage;
        }
    }

    onChange() {
        const data = this.props.data;
        data.company_name = data._invoice_type === "company" ? data._company_name : "";

        this.validate();
        this.props.onChange();
    }

    validate() {
        const data = this.props.data;

        let isValid = true;
        if (!this.isValidInvoiceType(data._invoice_type) ||
            !this.isNotEmpty(data.first_name) ||
            !this.isNotEmpty(data.last_name) ||
            !this.isValidCompanyName(data.company_name) ||
            !this.isNotEmpty(data.city) ||
            !this.isNotEmpty(data.zip_code) ||
            !this.isNotEmpty(data.address1) ||
            !this.isValidEmail(data.email) ||
            !this.isValidLanguage(data.language)
        ) {
            isValid = false;
        }

        Validator.markAs(data, isValid);

        this.props.validate();
    }

    handleValue(property, event) {
        this.props.data[property] = event.target.value;
        this.onChange();
    }

    isNotEmpty(value) {
        return value.trim().length > 0;
    }

    isValidEmail(value) {
        return /\S+@\S+\.\S+/.test(value.trim());
    }

    isValidLanguage(value) {
        return ["de", "fr"].includes(value);
    }

    isValidInvoiceType(value) {
        return ["individual", "company"].includes(value);
    }

    isValidCompanyName(value) {
        const length = value.trim().length;
        if (this.props.data._invoice_type !== "company") {
            return length === 0;
        }

        return length > 0;
    }

    render() {
        const {data, showValidationErrors} = this.props;
        const pageLanguage = this.context;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <b><Translate>payment_method.invoice.region_restriction</Translate></b>
                    </div>
                </div>
                <div className="row invoice-type">
                    <div className="col-md-12">
                        <CustomInput type="radio" id="invoice-type-individual" name="invoiceType" value="individual"
                                     invalid={showValidationErrors && !this.isValidInvoiceType(data._invoice_type)}
                                     className="mb-3 float-left" checked={data._invoice_type === "individual"}
                                     onChange={(e) => this.handleValue("_invoice_type", e)}>
                            <label className="custom-control-label" htmlFor="invoice-type-individual">
                                <Translate>payment_method.invoice.type.individual</Translate>
                            </label>
                        </CustomInput>
                        <CustomInput type="radio" id="invoice-type-company" name="invoiceType" value="company"
                                     invalid={showValidationErrors && !this.isValidInvoiceType(data._invoice_type)}
                                     className="mb-3 float-left" checked={data._invoice_type === "company"}
                                     onChange={(e) => this.handleValue("_invoice_type", e)}>
                            <label className="custom-control-label" htmlFor="invoice-type-company">
                                <Translate>payment_method.invoice.type.company</Translate>
                            </label>
                        </CustomInput>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <Label for="invoice-first-name" className="required">
                            <Translate>payment_method.invoice.first_name</Translate>
                        </Label>
                        <Input type="text" id="invoice-first-name" autoComplete="off" placeholder="Jane"
                               value={data.first_name} onChange={(e) => this.handleValue("first_name", e)}
                               invalid={showValidationErrors && !this.isNotEmpty(data.first_name)}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Label for="invoice-last-name" className="required">
                            <Translate>payment_method.invoice.last_name</Translate>
                        </Label>
                        <Input type="text" id="invoice-last-name" autoComplete="off" placeholder="Doe"
                               value={data.last_name} onChange={(e) => this.handleValue("last_name", e)}
                               invalid={showValidationErrors && !this.isNotEmpty(data.last_name)}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                </div>
                {data._invoice_type === "company" &&
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <Label for="invoice-company-name" className="required">
                                <Translate>payment_method.invoice.company_name</Translate>
                            </Label>
                            <Input type="text" id="invoice-company-name" autoComplete="off" value={data._company_name}
                                   invalid={showValidationErrors && !this.isValidCompanyName(data._company_name)}
                                   onChange={(e) => this.handleValue("_company_name", e)}/>
                            <FormFeedback>
                                <Translate>error.validation.invalid_value</Translate>
                            </FormFeedback>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <Label for="invoice-address" className="required">
                            <Translate>payment_method.invoice.address</Translate>
                        </Label>
                        <Input type="text" id="invoice-address" autoComplete="off" className="mb-3"
                               value={data.address1} onChange={(e) => this.handleValue("address1", e)}
                               invalid={showValidationErrors && !this.isNotEmpty(data.address1)}/>
                        <Input type="text" autoComplete="off"
                               value={data.address2} onChange={(e) => this.handleValue("address2", e)}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <Label for="invoice-zip-code" className="required">
                            <Translate>payment_method.invoice.zip_code</Translate>
                        </Label>
                        <Input type="text" id="invoice-zip-code" autoComplete="off"
                               value={data.zip_code} onChange={(e) => this.handleValue("zip_code", e)}
                               invalid={showValidationErrors && !this.isNotEmpty(data.zip_code)}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Label for="invoice-city" className="required">
                            <Translate>payment_method.invoice.city</Translate>
                        </Label>
                        <Input type="text" id="invoice-city" autoComplete="off"
                               value={data.city} onChange={(e) => this.handleValue("city", e)}
                               invalid={showValidationErrors && !this.isNotEmpty(data.city)}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 mb-3">
                        <Label for="invoice-email" className="required">
                            <Translate>payment_method.invoice.email</Translate>
                        </Label>
                        <Input type="email" id="invoice-email" autoComplete="off" placeholder="jane@example.com"
                               value={data.email} onChange={(e) => this.handleValue("email", e)}
                               invalid={showValidationErrors && !this.isValidEmail(data.email)}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                    <div className="col-md-4 mb-3">
                        <Label for="invoice-language" className="required">
                            <Translate>payment_method.invoice.language</Translate>
                        </Label>
                        <CustomInput type="select" id="invoice-language" autoComplete="off"
                                     value={data.language} onChange={(e) => this.handleValue("language", e)}
                                     invalid={showValidationErrors && !this.isValidLanguage(data.language)}>
                            <option value="de"
                                    label={Translate.translate(pageLanguage, "payment_method.invoice.language.de")}/>
                            <option value="fr"
                                    label={Translate.translate(pageLanguage, "payment_method.invoice.language.fr")}/>
                        </CustomInput>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Invoice.contextType = LanguageContext;

export default Invoice;
