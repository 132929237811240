class ErrorHandler {
    static logCritical(error) {
        if (error === null || error === undefined) {
            return;
        }

        if (typeof Rollbar == 'object') {
            Rollbar.critical((typeof error === 'string') ? new Error(error) : error);
        }
    }
}

export default ErrorHandler
