import React from "react";
import {Fade} from "reactstrap";
import FatalError from "./FatalError";

class Loader extends React.Component {
    render() {
        return (
            <React.Fragment>
                {(this.props.isLoading || this.props.fatalError !== null) &&
                <div className="panel p-2 d-flex flex-row align-items-center justify-content-end">
                    <div className="d-flex justify-content-end">
                        <div className="p-4">
                            <a href="#" onClick={this.props.onPageClose}>
                                <div className="abort"/>
                            </a>
                        </div>
                    </div>
                </div>
                }
                <Fade in={this.props.isLoading}>
                    <div className="spinner-global"/>
                </Fade>
                {!this.props.isLoading && this.props.fatalError === null &&
                <Fade>
                    {this.props.children}
                </Fade>
                }
                {this.props.fatalError !== null &&
                <Fade>
                    <FatalError message={this.props.fatalError}/>
                </Fade>
                }
            </React.Fragment>
        );
    }
}

export default Loader;
