import React from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import Translate from "./Translate";
import {LanguageContext} from "../contexts/language";

class LanguageSwitcher extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {isOpen: false};
    }

    toggle() {
        this.setState({isOpen: !this.state.isOpen});
    }

    render() {
        const pageLanguage = this.context;
        const {onChange} = this.props;
        const dropdownId = "language-switcher-dropdown";

        return (
            <div className="p-4">
                <Dropdown isOpen={this.state.isOpen} toggle={this.toggle} direction="left">
                    <DropdownToggle id={dropdownId}
                                    color="none"
                                    tag="a"
                                    href="#"
                                    title={Translate.translate(pageLanguage, "language_switcher.label")}
                                    role={"button"}
                                    aria-label={Translate.translate(pageLanguage, "language_switcher.label")}
                                    aria-haspopup={true}>
                        <div className="language"></div>
                    </DropdownToggle>
                    <DropdownMenu aria-labelledby={dropdownId}>
                        <DropdownItem onClick={() => onChange("de")}
                                      aria-label={Translate.translate(pageLanguage, "language_switcher.option.label", {"language": Translate.translate(pageLanguage, "language.de")})}>
                            <Translate>language.de</Translate>
                        </DropdownItem>
                        <DropdownItem onClick={() => onChange("en")}
                                      aria-label={Translate.translate(pageLanguage, "language_switcher.option.label", {"language": Translate.translate(pageLanguage, "language.en")})}>
                            <Translate>language.en</Translate>
                        </DropdownItem>
                        <DropdownItem onClick={() => onChange("es")}
                                      aria-label={Translate.translate(pageLanguage, "language_switcher.option.label", {"language": Translate.translate(pageLanguage, "language.es")})}>
                            <Translate>language.es</Translate>
                        </DropdownItem>
                        <DropdownItem onClick={() => onChange("fr")}
                                      aria-label={Translate.translate(pageLanguage, "language_switcher.option.label", {"language": Translate.translate(pageLanguage, "language.fr")})}>
                            <Translate>language.fr</Translate>
                        </DropdownItem>
                        <DropdownItem onClick={() => onChange("it")}
                                      aria-label={Translate.translate(pageLanguage, "language_switcher.option.label", {"language": Translate.translate(pageLanguage, "language.it")})}>
                            <Translate>language.it</Translate>
                        </DropdownItem>
                        <DropdownItem onClick={() => onChange("pt")}
                                      aria-label={Translate.translate(pageLanguage, "language_switcher.option.label", {"language": Translate.translate(pageLanguage, "language.pt")})}>
                            <Translate>language.pt</Translate>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }
}

LanguageSwitcher.contextType = LanguageContext;

export default LanguageSwitcher;
