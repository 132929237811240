import React from "react";
import PropTypes from "prop-types";

const declineCodes = ['00', '01', '02', '03', '04', '05', '06', '07', '10', '12', '13', '14', '15', '19', '1A', '22',
    '28', '30', '31', '34', '37', '41', '42', '43', '49', '51', '54', '55', '56', '57', '58', '59', '61', '62', '63',
    '65', '67', '70', '75', '80', '85', '91', '92', '93', '96', 'CV', 'R0', 'R1', '000', '001', '002', '100', '101',
    '106', '109', '110', '111', '115', '117', '119', '122', '125', '130', '181', '183', '187', '189', '200', '900',
    '911', '912'
]

class DeclineExplanation extends React.Component {
    render() {
        const {details} = this.props;

        if (details && details.length > 0) {
            const [title, message, stepsTitle, ...steps] = details;
            const elements = [<p key={'p1'}>{[title, message].filter((s) => s).join(' ').trim()}</p>];

            const stepsEl = [];
            for (let i = 0; i < (steps || []).length; i++) {
                stepsEl.push(<li key={'li' + i}>{steps[i]}</li>);
            }

            if (stepsTitle && stepsEl.length) {
                elements.push(<p className={'next-actions'} key={'p2'}>{stepsTitle}</p>);
                elements.push(<ul key={'steps'}>{stepsEl}</ul>);
            }

            return (<div className={'decline-explanation'}>{elements}</div>);
        }

        return null;
    }
}

DeclineExplanation.propTypes = {
    details: PropTypes.arrayOf(PropTypes.string),
}

export default DeclineExplanation;
