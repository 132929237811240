export default {
    "country.at": "L'Autriche",
    "country.be": "Belgique",
    "country.ch": "Suisse",
    "country.de": "Allemagne",
    "country.es": "Espagne",
    "country.it": "Italie",
    "country.nl": "Pays-Bas",

    "select": "Sélectionner",

    "page.title": "Checkout",

    "payment_method.credit_card.title": "Carte",
    "payment_method.credit_card.number": "Numéro de carte",
    "payment_method.credit_card.holder": "Titulaire",
    "payment_method.credit_card.expiry": "Date d'expiration",
    "payment_method.credit_card.cvc": "CVC",

    "payment_method.direct_debit.title": "Débit direct SEPA",
    "payment_method.direct_debit.email_address": "Adresse e-mail",
    "payment_method.direct_debit.holder": "Nom du titulaire du compte",
    "payment_method.direct_debit.iban": "IBAN",
    "payment_method.direct_debit.bank_name": "Nom de la banque",
    "payment_method.direct_debit.bic": "BIC",

    "payment_method.ideal.title": "iDEAL",
    "payment_method.ideal.bic": "Nom de la banque",

    "payment_method.sofort.title": "Sofortüberweisung",
    "payment_method.sofort.bank_country": "Pays de la banque",

    "payment_method.alipay.title": "Alipay",
    "payment_method.twint.title": "TWINT",
    "payment_method.apple_pay.title": "Apple Pay",
    "payment_method.google_pay.title": "Google Pay",

    "payment_method.giropay.title": "Giropay",
    "payment_method.giropay.holder_name": "Nom du titulaire du compte",

    "payment_method.eps.title": "EPS",
    "payment_method.eps.holder_name": "Nom du titulaire du compte",

    "payment_method.invoice.title": "Le paiement par facture",
    "payment_method.invoice.type.individual": "Private customer",
    "payment_method.invoice.type.company": "Business customer",
    "payment_method.invoice.first_name": "Prénom",
    "payment_method.invoice.last_name": "Nom de famille",
    "payment_method.invoice.company_name": "Company Name",
    "payment_method.invoice.city": "Ville",
    "payment_method.invoice.zip_code": "Code postal",
    "payment_method.invoice.address": "Adresse",
    "payment_method.invoice.email": "Courriel",
    "payment_method.invoice.language": "Langue",
    "payment_method.invoice.language.de": "Allemand",
    "payment_method.invoice.language.fr": "Français",
    "payment_method.invoice.region_restriction": "Le paiement par facture n'est actuellement possible que pour les personnes résidant en Suisse. Une adresse postale suisse valide est requise.",

    "payment_method.crypto.title": "Pay by crypto currency",

    "payment_method.postfinance.title": "PostFinance",
    "payment_method.postfinance.auth_type": "Mode de paiement",
    "payment_method.postfinance.auth_type.card": "Carte / App",
    "payment_method.postfinance.auth_type.efinance": "E-Finance",
    "payment_method.postfinance.language": "Langue",
    "payment_method.postfinance.language.de": "Allemand",
    "payment_method.postfinance.language.fr": "Français",
    "payment_method.postfinance.language.it": "Italien",
    "payment_method.postfinance.language.en": "Anglais",

    "insurance.refund_protect.title": "Réservation remboursable",
    "insurance.trekksoft_standard.title": "assurance annulation",

    "insurance.description.line1": "Pour ne pas avoir à supporter le risque financier en cas d'annulation, nous vous recommandons de souscrire à notre assurance frais d'annulation",
    "insurance.description.line2": "Evénements assurés",
    "insurance.description.line3": "(extrait des conditions générales d'assurance)",
    "insurance.description.line4": "Vous pouvez trouver le service exact dans ",
    "insurance.description.line5": "les conditions générales d'assurance",
    "insurance.description.line6": "En cas de questions, appelez-nous ou écrivez-nous un e-mail",
    "insurance.coverage.line1": "Maladie, grossesse, accident, décès",
    "insurance.coverage.line2": "Dommages à la propriété résidentielle au domicile",
    "insurance.coverage.line3": "Retard et annulation du moyen de transport à l'aller",
    "insurance.coverage.line4": "Grèves et dangers à destination",
    "insurance.coverage.line5": "Chômage / début d'emploi inattendu",

    "cart.title": "Votre panier",
    "cart.show": "Show Cart",
    "cart.hide": "Hide Cart",

    "person.title": "Invité",
    "person.firstname": "Prénom",
    "person.lastname": "Nom de famille",

    "terms.dialog.title": "Termes et conditions",

    "cvc.help.title": "Où puis-je trouver mon numéro de CVV / CVC ?",
    "cvc.help.maestro": "Les cartes de débit belges n'ont pas de code de vérification de carte (CVC). Pour confirmer le paiement, veuillez laisser ce champ vide. / Entrez 000 ou 999 pour confirmer le paiement",

    "dialog.button.close": "Fermer",

    "error.payment_method.no_payment_method_is_configured": "Aucune méthode de paiement n'est configurée. Veuillez contacter le vendeur.",
    "error.validation.invalid_value": "Valeur invalide",
    "error.validation.check_input": "S'il vous plaît, vérifiez votre saisie",
    "error.fatal.title": "Quelque chose d'inattendu s'est produit",
    "error.fatal.description": "Désolé, mais quelque chose a mal tourné. Veuillez réessayer ou prendre contact avec nous",
    "error.fatal.message": "Message d'erreur",
    "error.payment.payment_failed": "Paiement échoué. Veuillez réessayer ou prendre contact avec nous",
    "error.payment.3ds_authentication_failed": "L'authentification 3DS a échoué. Veuillez réessayer ou prendre contact avec nous.",

    "step.title.addons": "Paiement remboursable",
    "step.title.payment": "Mode de paiement",
    "step.title.google_pay": "Google Pay",

    "step.breadcrumb.addons": "Paiement remboursable",
    "step.breadcrumb.payment": "Paiement",
    "step.breadcrumb.google_pay": "Google Pay",

    "action.next": "Suivant",
    "action.pay": "Payer",
    "action.more": "En savoir plus",

    "action.abort.title": "Annuler le paiement",

    "dcc.title": "Choisissez la monnaie de paiement",
    "dcc.alternate_payment_method_description": "Cette méthode de paiement alternative n'autorise que le %devise% comme devise. Si vous souhaitez payer dans une autre devise, veuillez sélectionner un autre mode de paiement",
    "dcc.fees_info": "comprend tous les frais et charges",
    "dcc.conversion_by_payyo": "%price%, conversion par Payyo",
    "dcc.conversion_by_issuer": "%price%, conversion par l'émetteur de la carte",

    "footer.line1": "Veuillez contacter %supportEmail% pour toute question relative au paiement",
    "footer.line2": "© 2010 - %year% TrekkSoft AG. Fabriqué en Suisse",

    "countdown.title": "%time% restantes pour terminer le paiement",

    "rebate.title": "Rabais",

    "refund_protect.recommended": "Recommended",
    "refund_protect.protection_selected": "Refundable Payment",
    "refund_protect.instructions_1": "Surclassez votre réservation et recevez un remboursement à 100%, en cas d'annulation avant la date de la prestation, si vous ne pouvez pas assister à la prestation et que vous pouvez prouver l'une des raisons définies dans nos conditions générales d'utilisation.",
    "refund_protect.instructions_2": "Conditions générales de vente",
    "refund_protect.instructions_powered_by": "Refundable.me",
    "refund_protect.reasons.covid_19": "COVID-19 infection et isolement",
    "refund_protect.reasons.covid_19_link": "Voir les détails",
    "refund_protect.reasons.reasons": "Demande de remboursement intégral en cas de",
    "refund_protect.reasons.reason_0": "Maladie ou blessure de dernière minute (y compris COVID-19)",
    "refund_protect.reasons.reason_1": "Maladie Accident et Blessure",
    "refund_protect.reasons.reason_2": "Urgences domestiques et familiales",
    "refund_protect.reasons.reason_3": "Défaillance du transport",
    "refund_protect.reasons.reason_4": "Vol de documents",
    "refund_protect.reasons.reason_5": "Condition médicale préexistante",
    "refund_protect.reasons.reason_6": "Convocation à la cour",
    "refund_protect.reasons.reason_7": "Panne de véhicule privé",
    "refund_protect.reasons.reason_8": "Emergency Services Recall",
    "refund_protect.reasons.reason_9": "Beaucoup plus...",
    "refund_protect.reasons.see_more": "Et plus encore",
    "refund_protect.protection_rejected": "Conditions de remboursement de base",
    "refund_protect.protection_rejected_terms": "J'accepte la politique de remboursement standard du fournisseur.",
};
