import InsuranceQuoteModel from "./InsuranceQuoteModel";
import DccModel from "./DccModel";
import McpModel from "./McpModel";
import RebateModel from "./RebateModel";

class CartModel {
    constructor(payment, extraCharges, insuranceQuote, mcp, dcc, rebate) {
        /** @type {PaymentModel} */
        this.payment = payment;
        /** @type {ExtraChargesModel} */
        this.extraCharges = extraCharges;
        /** @type {InsuranceQuoteModel} */
        this.insuranceQuote = insuranceQuote;
        /** @type {McpModel} */
        this.mcp = mcp;
        /** @type {DccModel} */
        this.dcc = dcc;
        /** @type {RebateModel} */
        this.rebate = rebate;
    }

    get total() {
        if (this.mcp instanceof McpModel) {
            return this.mcp.price;
        }

        /** @type {Money} */
        let total = this.payment.price;

        if (this.rebate instanceof RebateModel) {
            total = total.subtract(this.rebate.charge);
        }

        if (!this.extraCharges.isEmpty()) {
            total = total.add(this.extraCharges.totalPrice);
        }

        if (this.insuranceQuote instanceof InsuranceQuoteModel && this.insuranceQuote.isSelected) {
            total = total.add(this.insuranceQuote.premium);
        }

        return total;
    }

    get toPay() {
        let price = this.total;

        if (this.mcp instanceof McpModel) {
            price = this.mcp.price;
        }

        if (this.dcc instanceof DccModel) {
            price = this.dcc.quote.price;
        }

        return price;
    }

    showPrices() {
        return !(this.mcp instanceof McpModel);
    }
}

export default CartModel;
