import React from 'react';
import Translate from "../Translate";
import PropTypes from "prop-types";

function CovidBanner(props) {
    return <div className={props.className}>
        <Translate>{"refund_protect.reasons.covid_19"}</Translate>{" - "}
        <a className={props.className + "-see-more"} target={"_blank"} href={"https://www.refundable.me/covid"}><Translate>{"refund_protect.reasons.covid_19_link"}</Translate></a>
    </div>;
}

CovidBanner.propTypes = {
    className: PropTypes.string,
}

CovidBanner.defaultProps = {
    className: '',
};

export default CovidBanner;
