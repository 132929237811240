import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Translate from "../Translate";
import {LanguageContext} from "../../contexts/language";

class TermsModal extends React.Component {

    render() {
        const pageLanguage = this.context;

        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.onHide}>
                <ModalHeader>
                    <Translate>terms.dialog.title</Translate>
                </ModalHeader>
                <ModalBody>
                    <div dangerouslySetInnerHTML={{__html: this.props.text}}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="pink" onClick={this.props.onHide}
                            aria-label={Translate.translate(pageLanguage, "terms.button.label")}>
                        <Translate>dialog.button.close</Translate>
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

TermsModal.contextType = LanguageContext;

export default TermsModal;
