import React from "react";
import {CustomInput, FormFeedback, Label} from 'reactstrap';
import Validator from "../../../library/Validator";
import Translate from "../../Translate";

class IDeal extends React.Component {

    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleBankBic = this.handleBankBic.bind(this);

        if (this.props.data.bank === undefined) {
            this.props.data.bank = {bic: ""};
        }

        this.validate();
    }

    validate() {
        const data = this.props.data;

        let isValid = true;
        if (!this.isValidBankBic(data.bank.bic)) {
            isValid = false;
        }

        Validator.markAs(data, isValid);

        this.props.validate();
    }

    onChange() {
        this.validate();
        this.props.onChange();
    }

    handleBankBic(event) {
        this.props.data.bank.bic = event.target.value;
        this.onChange();
    }

    isValidBankBic(bic) {
        if (this.isBicRequired()) {
            return bic.length > 0;
        }

        return true;
    }

    isBicRequired() {
        return this.props.extraFields.includes('bic');
    }

    render() {
        const {data, showValidationErrors} = this.props;

        if (!this.isBicRequired()) {
            return null;
        }

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <Label for="ideal-bank-bic" className="required">
                            <Translate>payment_method.ideal.bic</Translate>
                        </Label>
                        <CustomInput type="select"
                                     id="ideal-bank-bic"
                                     autoComplete="off"
                                     value={data.bank.bic}
                                     onChange={this.handleBankBic}
                                     invalid={showValidationErrors && !this.isValidBankBic(data.bank.bic)}>
                            <option className="placeholder" value="">Select</option>
                            <option value="ABNANL2A">ABN Amro Bank</option>
                            <option value="ASNBNL21">ASN Bank</option>
                            <option value="BUNQNL2A">bunq</option>
                            <option value="INGBNL2A">ING</option>
                            <option value="KNABNL2H">Knab</option>
                            <option value="RABONL2U">Rabobank</option>
                            <option value="RGGINL21">Regio Bank</option>
                            <option value="SNSBNL2A">SNS Bank</option>
                            <option value="TRIONL2U">Triodos Bank</option>
                            <option value="FVLBNL22">Van Lanschot Bankiers</option>
                        </CustomInput>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default IDeal;
