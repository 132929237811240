import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Translate from "../Translate";
import imageCVCHelp from '../../assets/cvc.png';
import {LanguageContext} from "../../contexts/language";

class CvcHelp extends React.Component {

    render() {
        const pageLanguage = this.context;

        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.onHide}>
                <ModalHeader>
                    <div><Translate>cvc.help.title</Translate></div>
                    <div><small><Translate>cvc.help.maestro</Translate></small></div>
                </ModalHeader>
                <ModalBody>
                    <img className="img-fluid" src={imageCVCHelp}
                         alt={Translate.translate(pageLanguage, "cvc.help.example_image.description")}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="pink" onClick={this.props.onHide}>
                        <Translate>dialog.button.close</Translate>
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

CvcHelp.contextType = LanguageContext;

export default CvcHelp;
