import React from "react";
import PaymentMethod from "../models/PaymentMethod";
import Validator from "../library/Validator";
import Method from "./Payment/Method";

class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChoose = this.onChoose.bind(this);

        this.validate();
    }

    validate() {
        const {payment, onValidate} = this.props;
        const sm = payment.getSelectedMethod();

        if (!(sm instanceof PaymentMethod)) {
            Validator.markAs(payment, false);
        } else {
            onValidate(true);
            Validator
                .validate(sm.data)
                .then((res) => {
                    Validator.markAs(payment, res);
                    onValidate(false);
                });
        }
    }

    onChange() {
        this.props.onChange();
    }

    onChoose(type) {
        this.props.onChoose(type);
        this.validate();
    }

    getMethodComponent(method, price) {
        const check = method.checkComponent;
        const Component = method.component;
        const CheckedMethod = check(Method, price.amount, price.currency, method.options['options'] || {});
        const {type, data, customer, options} = method;

        return (
            <CheckedMethod key={method.type} method={method} payment={this.props.payment} onChoose={this.onChoose}>
                {Component &&
                <Component key={type}
                           data={data}
                           customer={customer}
                           extraFields={options['extra_fields'] || []}
                           validate={this.validate}
                           onChange={this.onChange}
                           ptpMode={this.props.ptpMode}
                           ptpFieldUri={this.props.ptpFieldUri}
                           showValidationErrors={this.props.showValidationErrors}/>
                }
            </CheckedMethod>
        );
    }

    render() {
        const that = this;
        const {payment, areaLabelBy} = this.props;

        const methods = payment.methods.map(m => that.getMethodComponent(m, this.props.payment.price));

        if (methods.length === 0) {
            return null;
        }

        return (
            <React.Fragment>
                <div className="list-group mb-3" role={"radiogroup"} aria-labelledby={areaLabelBy}>{methods}</div>
            </React.Fragment>
        );
    }
}

export default Payment;
