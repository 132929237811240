import React from 'react';
import {loadStripe} from "../../../library/StripeClient";

const PROVIDER_STRIPE = 'stripe';
const ENVIRONMENT = process.env.NODE_ENV;

let GooglePayWithAvailabilityCheckComponent = null;

export default function withAvailabilityCheck(WrappedComponent, amount, currency, options) {
    if (GooglePayWithAvailabilityCheckComponent !== null) {
        return GooglePayWithAvailabilityCheckComponent;
    }

    return GooglePayWithAvailabilityCheckComponent = class extends React.Component {
        constructor(props) {
            super(props);

            this.amount = amount;
            this.currency = currency;
            this.options = options;
            this.isComponentMounted = false;
            this.state = {isAvailable: false};

            this.handleAvailabilityChanged = this.handleAvailabilityChanged.bind(this);
        }

        componentDidMount() {
            this.isComponentMounted = true;
            const {currency, amount, options: {provider, publishable_key, country_code}, handleAvailabilityChanged} = this;

            if (provider !== PROVIDER_STRIPE) {
                handleAvailabilityChanged(false);
                return;
            }

            const that = this;

            loadStripe(publishable_key).then(stripe => {
                if (!stripe) {
                    return;
                }

                const paymentRequest = stripe.paymentRequest({
                    country: country_code,
                    currency: currency.toLowerCase(),
                    total: {
                        label: 'Payment Check',
                        amount: amount,
                    },
                    requestPayerName: true,
                    requestPayerEmail: true,
                });

                return paymentRequest.canMakePayment().then(result => {
                    if (!that.isComponentMounted) {
                        return;
                    }

                    let isAvailable = false;
                    // result is not null if `Payment API` is available
                    // but only `applePay` must be false to be sure that it is Google Pay
                    if (result && !result.applePay) {
                        isAvailable = true;
                    }

                    if (ENVIRONMENT === 'development' && !isAvailable) {
                        console.warn('[PAYYO] Google Pay is NOT possible. But we display it for DEVELOPMENT', result);
                        handleAvailabilityChanged(true);
                        return;
                    }

                    handleAvailabilityChanged(isAvailable);
                }, reason => console.error(reason));
            });
        }

        componentWillUnmount() {
            this.isComponentMounted = false;
        }

        handleAvailabilityChanged(val) {
            this.setState({isAvailable: val});
        }

        render() {
            if (!this.state.isAvailable) {
                return null;
            }

            return <WrappedComponent {...this.props}/>;
        }
    };
}
