function getParameterByName(name, url = null) {
    if (!url) {
        url = window.location.search;
    }

    if (typeof URLSearchParams === "function") {
        const urlParams = new URLSearchParams(url);
        return urlParams.get(name);
    }

    return getParameterByNameLegacy(name, url);
}

function getParameterByNameLegacy(name, url) {
    if (!url) {
        url = window.location.href;
    }

    name = name.replace(/[\[\]]/g, '\\$&');

    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);

    if (!results) {
        return null;
    }

    if (!results[2]) {
        return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function encodeQueryData(data) {
    const ret = [];
    for (let d in data) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }

    return ret.join('&');
}

export {getParameterByName, encodeQueryData}
