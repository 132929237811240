export default {
    "country.at": "Áustria",
    "country.be": "Bélgica",
    "country.ch": "Suíça",
    "country.de": "Alemanha",
    "country.es": "Espanha",
    "country.it": "Itália",
    "country.nl": "Holanda",

    "select": "Selecionar",

    "page.title": "Checkout",

    "payment_method.credit_card.title": "Cartão",
    "payment_method.credit_card.number": "Número do Cartão",
    "payment_method.credit_card.holder": "Titular",
    "payment_method.credit_card.expiry": "Data de Validade",
    "payment_method.credit_card.cvc": "CVC",

    "payment_method.direct_debit.title": "Débito Direto SEPA",
    "payment_method.direct_debit.email_address": "Endereço Eletrónico",
    "payment_method.direct_debit.holder": "Nome do titular da conta",
    "payment_method.direct_debit.iban": "IBAN",
    "payment_method.direct_debit.bank_name": "Nome do Banco",
    "payment_method.direct_debit.bic": "BIC",

    "payment_method.ideal.title": "iDEAL",
    "payment_method.ideal.bic": "Nome do Banco",

    "payment_method.sofort.title": "Sofortüberweisung",
    "payment_method.sofort.bank_country": "País do Banco",

    "payment_method.alipay.title": "Alipay",
    "payment_method.twint.title": "TWINT",
    "payment_method.apple_pay.title": "Apple Pay",
    "payment_method.google_pay.title": "Google Pay",

    "payment_method.giropay.title": "Giropay",
    "payment_method.giropay.holder_name": "Nome completo do titular da conta",

    "payment_method.eps.title": "EPS",
    "payment_method.eps.holder_name": "Nome completo do titular da conta",

    "payment_method.invoice.title": "Pagamento por Fatura/Boleto",
    "payment_method.invoice.type.individual": "Private customer",
    "payment_method.invoice.type.company": "Business customer",
    "payment_method.invoice.first_name": "Nome",
    "payment_method.invoice.last_name": "Apelido",
    "payment_method.invoice.company_name": "Company Name",
    "payment_method.invoice.city": "Localidade",
    "payment_method.invoice.zip_code": "Código Postal/CEP",
    "payment_method.invoice.address": "Endereço",
    "payment_method.invoice.email": "Email",
    "payment_method.invoice.language": "Lingua",
    "payment_method.invoice.language.de": "Alemão",
    "payment_method.invoice.language.fr": "Francês",
    "payment_method.invoice.region_restriction": "Pagamento por Fatura/Boleto só está actualmente dispoinível para residentes na Suiça. É necessário possuir uma morada suiça válida.",

    "payment_method.crypto.title": "Pay by crypto currency",

    "payment_method.postfinance.title": "PostFinance",
    "payment_method.postfinance.auth_type": "Payment Type",
    "payment_method.postfinance.auth_type.card": "Card / App",
    "payment_method.postfinance.auth_type.efinance": "E-Finance",
    "payment_method.postfinance.language": "Language",
    "payment_method.postfinance.language.de": "German",
    "payment_method.postfinance.language.fr": "French",
    "payment_method.postfinance.language.it": "Italian",
    "payment_method.postfinance.language.en": "English",

    "insurance.refund_protect.title": "Reserva reembolsável",
    "insurance.trekksoft_standard.title": "Seguro de Cancelamento",

    "insurance.description.line1": "Para que não tenha que suportar o risco financeiro no caso de cancelamento, nós recomendamos que utilize o seguro de cancelamento.",
    "insurance.description.line2": "Eventos cobertos",
    "insurance.description.line3": "excerto dos termos gerais do seguro)",
    "insurance.description.line4": "Poderá encontrar o exacto serviço",
    "insurance.description.line5": "nos termos gerais do seguro",
    "insurance.description.line6": "Caso tenha alguma questão poderá ligar-nos ou contactar-nos por e-mail",
    "insurance.coverage.line1": "Doença, gravidez, acidente, morte",
    "insurance.coverage.line2": "danos em propriedade habitucional",
    "insurance.coverage.line3": "Atraso ou cancelamento dos meios de transporte em viagens para o exterior",
    "insurance.coverage.line4": "Greves e tumultos no destino",
    "insurance.coverage.line5": "Desemprego / Alteração inesperada de emprego",

    "cart.title": "O seu cartão",
    "cart.show": "Mostrar cartão",
    "cart.hide": "Esconder cartão",

    "person.title": "Hóspede",
    "person.firstname": "Nome",
    "person.lastname": "Apelido",

    "terms.dialog.title": "Termos e Condições",

    "cvc.help.title": "Onde posso encontrar o meu número de CVV/CVC?",
    "cvc.help.maestro": "Os cartões de débito belgas não têm código de verificação do cartão (CVC). Para confirmar o pagamento, por favor deixe o campo em branco./ Coloque 0000 ou 999 para confirmar o pagamento.",

    "dialog.button.close": "Fechar",

    "error.payment_method.no_payment_method_is_configured": "Nenhum método de pagamento está configurado. Por favor contacte o vendedor.",
    "error.validation.invalid_value": "Valor inválido",
    "error.validation.check_input": "Por favor verifique os dados inseridos",
    "error.fatal.title": "Algo inesperado aconteceu",
    "error.fatal.description": "Pedimos desculpa mas algo correu mal. Por favor volte a tentar ou entre em contato connosco.",
    "error.fatal.message": "Mensagem de erro",
    "error.payment.payment_failed": "Pagamento Falhou. Por favor volte a tentar ou entre em contacto connosco",
    "error.payment.3ds_authentication_failed": "A autenticação 3DS falhou. Por favor volte a tentar ou entre em contato connosco.",

    "step.title.addons": "Pagamento reembolsável",
    "step.title.payment": "Método de pagamento",
    "step.title.google_pay": "Google Pay",

    "step.breadcrumb.addons": "Pagamento reembolsável",
    "step.breadcrumb.payment": "Pagamento",
    "step.breadcrumb.google_pay": "Google Pay",

    "action.next": "Próxima",
    "action.pay": "Pagar",
    "action.more": "Mais informação",

    "action.abort.title": "Abortar o pagamento",

    "dcc.title": "Escolher moeda para pagar",
    "dcc.alternate_payment_method_description": "Este método de pagamento apenas permite  %currency%  como moeda. Se  pretende escolher outra moeda por favor escolha outro método de pagamento.",
    "dcc.fees_info": "inclui todas as taxas e encargos",
    "dcc.conversion_by_payyo": "%price%, Conversão pela Payyo",
    "dcc.conversion_by_issuer": "%price%, Conversão pelo emissor do cartão",

    "footer.line1": "Por favor contacte %supportEmail%para todos os assuntos referentes a pagamentos.",
    "footer.line2": "© 2010 - %year% TrekkSoft AG. Made in Suiça.",

    "countdown.title": "%time% remanescente para terminar o pagamento",

    "rebate.title": "Desconto",

    "refund_protect.recommended": "Recomendado",
    "refund_protect.protection_selected": "Pagamento reembolsável",
    "refund_protect.instructions_1": "Actualize a sua reserva e receba um reembolso de 100%, em caso de cancelamento até à data do serviço, se não lhe for possível comparecer e puder apresentar provas por qualquer uma das razões definidas nos nossos Termos e Condições.",
    "refund_protect.instructions_2": "Desenvolvido por",
    "refund_protect.instructions_powered_by": "Refundable.me",
    "refund_protect.reasons.covid_19": "COVID-19 infecção e isolamento",
    "refund_protect.reasons.covid_19_link": "Ver detalhes",
    "refund_protect.reasons.reasons": "Pedir reembolso total no caso de",
    "refund_protect.reasons.reason_0": "Doença ou lesão de última hora (inc COVID-19)",
    "refund_protect.reasons.reason_1": "Acidente de Doença e Lesão",
    "refund_protect.reasons.reason_2": "Emergências Domésticas e Familiares",
    "refund_protect.reasons.reason_3": "Falha no transporte",
    "refund_protect.reasons.reason_4": "Roubo de Documentos",
    "refund_protect.reasons.reason_5": "Condição médica pré-existente",
    "refund_protect.reasons.reason_6": "Intimação do Tribunal",
    "refund_protect.reasons.reason_7": "Falha no veículo particular",
    "refund_protect.reasons.reason_8": "Revocação de Serviços de Emergência",
    "refund_protect.reasons.reason_9": "E muito mais…",
    "refund_protect.reasons.see_more": "E mais",
    "refund_protect.protection_rejected": "Termos Básicos de Reembolso",
    "refund_protect.protection_rejected_terms": "Aceito e concordo com a política de reembolso padrão do fornecedor",
};
