import Card from "../components/Payment/Method/Card";
import DirectDebit from "../components/Payment/Method/DirectDebit";
import IDeal from "../components/Payment/Method/IDeal";
import Validator from "../library/Validator";
import Sofort from "../components/Payment/Method/Sofort";
import withApplePayAvailabilityCheck from "../components/Payment/Method/ApplePay";
import withGooglePayAvailabilityCheck from "../components/Payment/Method/GooglePay";
import {withoutAvailabilityCheck} from "../components/Payment/Method";
import Giropay from "../components/Payment/Method/Giropay";
import Eps from "../components/Payment/Method/Eps";
import Invoice from "../components/Payment/Method/Invoice";
import PostFinance from "../components/Payment/Method/PostFinance";
import Klarna from "../components/Payment/Method/Klarna";

class PaymentMethod {

    constructor(type, schemes, options, data, customer) {
        this.type = type;
        this.schemes = schemes;
        this.options = options || {};
        this.data = data !== undefined ? data : {};
        this.customer = customer !== undefined ? customer : {};
        this.token = '';

        Validator.markAs(this.data, this.isAlwaysValid());
    }

    static get typeCreditCard() {
        return "credit_card";
    }

    static get typeCard() {
        return "card";
    }

    static get typeDirectDebit() {
        return "direct_debit";
    }

    static get typeIDeal() {
        return "ideal";
    }

    static get typeAlipay() {
        return "alipay";
    }

    static get typeSofort() {
        return "sofort";
    }

    static get typeTwint() {
        return "twint";
    }

    static get typeApplePay() {
        return "apple_pay";
    }

    static get typeGooglePay() {
        return "google_pay";
    }

    static get typeGiropay() {
        return "giropay";
    }

    static get typeEps() {
        return "eps";
    }

    static get typeInvoice() {
        return "invoice";
    }

    static get typeCrypto() {
        return "crypto";
    }

    static get typePostFinance() {
        return "postfinance";
    }

    static get typeKlarna() {
        return "klarna";
    }

    static get types() {
        return [
            PaymentMethod.typeCreditCard,
            PaymentMethod.typeDirectDebit,
            PaymentMethod.typeIDeal,
            PaymentMethod.typeAlipay,
            PaymentMethod.typeSofort,
            PaymentMethod.typeTwint,
            PaymentMethod.typeApplePay,
            PaymentMethod.typeGooglePay,
            PaymentMethod.typeGiropay,
            PaymentMethod.typeEps,
            PaymentMethod.typeInvoice,
            PaymentMethod.typeCrypto,
            PaymentMethod.typePostFinance,
            PaymentMethod.typeKlarna,
        ];
    }

    updateRequestData() {
        const {dataLoader} = this.data;

        if (dataLoader && dataLoader instanceof Function) {
            return dataLoader()
                .then(loaded => Object.assign(this.data, loaded));
        }

        return Promise.resolve(this.data);
    }

    getRequestData() {
        return this.updateRequestData()
            .then(d => {
                return {
                    type: this.type === PaymentMethod.typeCreditCard ? PaymentMethod.typeCard : this.type,
                    customer: this.customer,
                    data: d,
                }
            });
    }

    isAlwaysValid() {
        const extraFields = this.options['extra_fields'] || [];

        switch (this.type) {
            case PaymentMethod.typeSofort:
            case PaymentMethod.typeIDeal:
            case PaymentMethod.typeGiropay:
            case PaymentMethod.typeEps:
                return extraFields.length === 0;
            case PaymentMethod.typeAlipay:
            case PaymentMethod.typeTwint:
            case PaymentMethod.typeApplePay:
            case PaymentMethod.typeGooglePay:
            case PaymentMethod.typeCrypto:
                return true;
            default:
                return false;
        }
    }

    hasDccOptions() {
        switch (this.type) {
            case PaymentMethod.typeApplePay:
            case PaymentMethod.typeCrypto:
            case PaymentMethod.typeDirectDebit:
            case PaymentMethod.typeEps:
            case PaymentMethod.typeGiropay:
            case PaymentMethod.typeGooglePay:
            case PaymentMethod.typeIDeal:
            case PaymentMethod.typeInvoice:
            case PaymentMethod.typeKlarna:
            case PaymentMethod.typePostFinance:
            case PaymentMethod.typeSofort:
            case PaymentMethod.typeTwint:
                return false;
            default:
                return true;
        }
    }

    isDCCPossible() {
        switch (this.type) {
            case PaymentMethod.typeCard:
            case PaymentMethod.typeCreditCard:
            case PaymentMethod.typeDirectDebit:
            case PaymentMethod.typeEps:
            case PaymentMethod.typeGiropay:
            case PaymentMethod.typeIDeal:
            case PaymentMethod.typeInvoice:
            case PaymentMethod.typeKlarna:
            case PaymentMethod.typePostFinance:
            case PaymentMethod.typeSofort:
            case PaymentMethod.typeTwint:
                return true;
            default:
                return false;
        }
    }

    areAddonsBuyingPossible() {
        return true; // possible for all payment methods
    }

    get checkComponent() {
        switch (this.type) {
            case PaymentMethod.typeApplePay:
                return withApplePayAvailabilityCheck;
            case PaymentMethod.typeGooglePay:
                return withGooglePayAvailabilityCheck;
            default:
                return withoutAvailabilityCheck;
        }
    }

    get component() {
        const extraFields = this.options['extra_fields'] || [];

        switch (this.type) {
            case PaymentMethod.typeCreditCard:
            case PaymentMethod.typeCard:
                return Card;
            case PaymentMethod.typeDirectDebit:
                return DirectDebit;
            case PaymentMethod.typeIDeal:
                return extraFields.length > 0 ? IDeal : null;
            case PaymentMethod.typeSofort:
                return extraFields.length > 0 ? Sofort : null;
            case PaymentMethod.typeGiropay:
                return extraFields.length > 0 ? Giropay : null;
            case PaymentMethod.typeEps:
                return extraFields.length > 0 ? Eps : null;
            case PaymentMethod.typeInvoice:
                return Invoice;
            case PaymentMethod.typePostFinance:
                return PostFinance;
            case PaymentMethod.typeKlarna:
                return Klarna;
            default:
                return null;
        }
    }
}

export default PaymentMethod;
