import React from "react";
import Security from "../Payment/Security";
import ActionButton from "./ActionButton";
import Translate, {WithLanguageContext} from "../Translate";

class PayButton extends React.Component {
    render() {
        const {isLoading, isDisabled, onAction, amount, isMobile, methodType, t} = this.props;

        const btn = (
            <ActionButton isLoading={isLoading}
                          isDisabled={isDisabled}
                          onClick={onAction}
                          ariaLabel={t("action.pay") + " " + amount}>
                <Translate>action.pay</Translate>
                {isMobile !== true && (" " + amount)}
            </ActionButton>
        );

        if (this.props.isMobile) {
            return btn;
        } else {
            return (
                <div
                    className="d-flex align-items-center flex-row justify-content-start flex-sm-column-reverse float-sm-right flex-md-row justify-content-md-between float-md-none">
                    <div className="d-flex flex-row align-items-center justify-content-start payment-method-security">
                        <Security methodType={methodType}/>
                    </div>
                    <div className="d-none d-md-block">
                        {btn}
                    </div>
                </div>
            );
        }
    }
}

export default WithLanguageContext(PayButton);
