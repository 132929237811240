class EventHandler {
    constructor(mode) {
        this.mode = mode === "widget" ? "widget" : "page";
    }

    close(abortUrl) {
        if (this.mode === "widget") {
            window.parent.postMessage({command: "close", abortUrl: abortUrl}, "*");
        } else if (abortUrl) {
            window.location = abortUrl;
        }
    }

    success(transactionId, successUrl) {
        if (this.mode === "widget") {
            const message = {
                command: "success",
                successUrl: successUrl,
                transactionId: transactionId
            };
            window.parent.postMessage(message, "*");
        } else {
            window.location = successUrl;
        }
    }
}

export default EventHandler