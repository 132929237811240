import React from "react";
import Countdown from "./Countdown";
import LanguageSwitcher from "./LanguageSwitcher";
import {WithLanguageContext} from "./Translate";

class Panel extends React.Component {
    render() {
        const {styling, countdown, language, onLanguageChange, onPageClose, t} = this.props;
        const s = styling || {};

        return (
            <React.Fragment>
                <div className="panel border-bottom p-2 d-flex flex-row align-items-center justify-content-between">
                    {s.logoUrl && <img className="pl-4" src={s.logoUrl} alt={s.merchantName}/>}
                    {!s.logoUrl && <span className="h4 pl-4 m-0">{s.merchantName}</span>}
                    <div className="d-flex justify-content-end">
                        <Countdown seconds={countdown} onFinish={onPageClose}/>
                        <LanguageSwitcher language={language} onChange={onLanguageChange}/>
                        <div className="p-4">
                            <a href="#"
                               onClick={onPageClose}
                               title={t("action.abort.title")}
                               role={"button"}>

                                <div className="abort"/>
                            </a>
                        </div>
                    </div>
                </div>
                <Countdown seconds={countdown} onFinish={onPageClose} isMobile={true}/>
            </React.Fragment>
        );
    }
}

export default WithLanguageContext(Panel);
