import Money from "../library/Money";

class DccModel {
    constructor(dcc) {
        this.price = new Money(dcc.amount, dcc.currency, dcc.minor_currency_digits);
        this.quote = new DccQuoteModel(dcc.quote);
        this.reference = dcc.reference;
    }

    getRequestData() {
        return {reference: this.reference};
    }
}

class DccQuoteModel {
    constructor(quote) {
        this.price = new Money(quote.amount, quote.currency, quote.minor_currency_digits);
        this.exchangeRate = quote.exchange_rate;
    }
}

export default DccModel;
