import React from "react";
import Translate from "./Translate";
import InsuranceQuoteModel from "../models/InsuranceQuoteModel";

class InsuranceQuote extends React.Component {
    render() {
        /** @type {InsuranceQuoteModel} */
        const insuranceQuote = this.props.insuranceQuote;
        if (!(insuranceQuote instanceof InsuranceQuoteModel) || !insuranceQuote.isSelected) {
            return null;
        }

        return (
            <React.Fragment>
                {this.props.showSeparator && <hr/>}
                <div className="d-flex justify-content-between">
                    <p className="mb-2 mb-sm-0 pr-1"><Translate>{`insurance.${insuranceQuote.insurancePlanId}.title`}</Translate></p>
                    {this.props.showPrices && <p className="mb-2 mb-sm-0 font-weight-bold text-nowrap">
                        {insuranceQuote.premium.format()}
                    </p>}
                </div>
            </React.Fragment>
        );
    }
}

export default InsuranceQuote;
