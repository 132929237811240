import React from "react";
import {CustomInput, FormFeedback, Label} from "reactstrap";
import Validator from "../../../library/Validator";
import Translate from "../../Translate";
import {LanguageContext} from "../../../contexts/language";

class PostFinance extends React.Component {

    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleValue = this.handleValue.bind(this);
        this.handleAuthType = this.handleAuthType.bind(this);

        const data = this.props.data;
        data.language = data.language !== undefined ? data.language : "de";
        data.auth_type = data.auth_type !== undefined ? data.auth_type : "card";
    }

    componentDidMount() {
        const pageLanguage = this.context;
        if (this.isValidLanguage(pageLanguage)) {
            this.props.data.language = pageLanguage;
        }
    }

    onChange() {
        this.validate();
        this.props.onChange();
    }

    validate() {
        const data = this.props.data;

        let isValid = true;
        if (!this.isValidAuthType(data.auth_type) || !this.isValidLanguage(data.language)) {
            isValid = false;
        }

        Validator.markAs(data, isValid);

        this.props.validate();
    }

    handleValue(property, event) {
        this.props.data[property] = event.target.value;
        this.onChange();
    }

    handleAuthType(event) {
        const authType = event.target.value;

        this.props.data.auth_type = authType;
        this.props.data.scheme = authType === 'card' ? 'pfcd' : 'pfef';
        this.onChange();
    }

    isValidAuthType(value) {
        return value === "card" || value === "efinance";
    }

    isValidLanguage(value) {
        return ["de", "fr", "it", "en"].includes(value);
    }

    render() {
        const { data, showValidationErrors } = this.props;
        const pageLanguage = this.context;

        return (
            <React.Fragment>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className={`postfinance-option ${data.auth_type === 'card' ? 'active' : ''}`}>
                            <CustomInput type="radio" id="postfinance-auth-type-card" value="card" checked={data.auth_type === 'card'} onChange={this.handleAuthType}>
                                <label className="custom-control-label" htmlFor="postfinance-auth-type-card">
                                    <Translate>payment_method.postfinance.auth_type.card</Translate>

                                    <div className="postfinance-image pfcd" title={Translate.translate(pageLanguage, "payment_method.postfinance.auth_type.card")}/>
                                </label>
                            </CustomInput>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={`postfinance-option ${data.auth_type === 'efinance' ? 'active' : ''}`}>
                            <CustomInput type="radio" id="postfinance-auth-type-efinance" value="efinance" checked={data.auth_type === 'efinance'} onChange={this.handleAuthType}>
                                <label className="custom-control-label" htmlFor="postfinance-auth-type-efinance">
                                    <Translate>payment_method.postfinance.auth_type.efinance</Translate>

                                    <div className="postfinance-image pfef" title={Translate.translate(pageLanguage, "payment_method.postfinance.auth_type.efinance")}/>
                                </label>
                            </CustomInput>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-6 mb-3">
                        <Label for="postfinance-language" className="required">
                            <Translate>payment_method.postfinance.language</Translate>
                        </Label>
                        <CustomInput type="select"
                                     id="postfinance-language"
                                     autoComplete="off"
                                     value={data.language}
                                     onChange={(e) => this.handleValue("language", e)}
                                     invalid={showValidationErrors && !this.isValidLanguage(data.language)}>
                            <option value="de" label={Translate.translate(pageLanguage, "payment_method.postfinance.language.de")}/>
                            <option value="fr" label={Translate.translate(pageLanguage, "payment_method.postfinance.language.fr")}/>
                            <option value="it" label={Translate.translate(pageLanguage, "payment_method.postfinance.language.it")}/>
                            <option value="en" label={Translate.translate(pageLanguage, "payment_method.postfinance.language.en")}/>
                        </CustomInput>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

PostFinance.contextType = LanguageContext;

export default PostFinance;
