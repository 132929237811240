import React from "react";
import Translate from "./Translate";

class Countdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {seconds: null};
    }

    componentDidMount() {
        this.startedAt = Date.now();
        this.timerID = setInterval(() => this.tick(), 1000);
        this.tick();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    stopTimer() {
        clearInterval(this.timerID);
        this.timerID = null;
    }

    tick() {
        if (this.props.seconds === null || this.timerID === null) {
            return;
        }

        const finishAt = parseInt(this.startedAt / 1000) + this.props.seconds;
        const seconds = finishAt - parseInt(Date.now() / 1000);

        if (seconds >= 0) {
            this.setState({seconds: seconds});
        } else {
            this.props.onFinish();
            this.stopTimer();
        }
    }

    formatTime(seconds) {
        if (seconds === null) {
            return '';
        }

        const date = new Date(null);
        date.setSeconds(seconds);

        if (date.getUTCHours()) {
            return '';
        }

        const ss = ('0' + date.getUTCSeconds()).substr(-2);
        const mm = date.getUTCMinutes();

        return mm + ':' + ss;
    }

    render() {
        const time = this.formatTime(this.state.seconds);
        if (time === '') {
            return null;
        }

        const warning = this.state.seconds < 5 * 60 ? " warning" : "";

        if (this.props.isMobile) {
            return (
                <div className={"d-block d-sm-none countdown mobile px-2" + warning}>
                    <div className="pl-4">
                        <Translate time={time}>countdown.title</Translate>
                    </div>
                </div>
            );
        }

        return (
            <div className="d-none d-sm-block py-4 px-2">
                <div className={"countdown" + warning}>
                    <Translate time={time}>countdown.title</Translate>
                </div>
            </div>
        );
    }
}

export default Countdown;
