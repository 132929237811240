import React from "react";
import {FormFeedback, Input, Label} from 'reactstrap';
import Translate from "../Translate";
import Validator from "../../library/Validator";

class Person extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.validate = this.validate.bind(this);

        this.validate(props.person);
    }

    isPropertyValid(value) {
        return value.trim().length > 0;
    }

    validate() {
        const {person} = this.props;

        const isFirstNameValid = this.isPropertyValid(person.firstName);
        const isLastNameValid = this.isPropertyValid(person.lastName);

        Validator.markAs(person, isFirstNameValid && isLastNameValid);
    }

    onChange(event) {
        const {person, onChange} = this.props;

        const target = event.target;
        person[target.name] = target.value;
        this.validate(person);
        onChange(person);
    }

    render() {
        /** @type {InsuranceQuotePersonModel} */
        const person = this.props.person;
        const showValidationErrors = this.props.showValidationErrors;

        return (
            <React.Fragment>
                <h3 className="h5 card-title mb-2">
                    <Translate>person.title</Translate> #{this.props.index}
                </h3>
                <div className="row">
                    <div className="col-md-6 pb-3">
                        <Label for={"firstName-" + person.reference} className="required">
                            <Translate>person.firstname</Translate>
                        </Label>
                        <Input type="text" id={"firstName-" + person.reference} name="firstName"
                               value={person.firstName} onChange={this.onChange} placeholder="Jane"
                               invalid={showValidationErrors && !this.isPropertyValid(person.firstName)}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                    <div className="col-md-6 pb-3">
                        <Label for={"lastName-" + person.reference} className="required">
                            <Translate>person.lastname</Translate>
                        </Label>
                        <Input type="text" id={"lastName-" + person.reference} name="lastName"
                               value={person.lastName} onChange={this.onChange} placeholder="Doe"
                               invalid={showValidationErrors && !this.isPropertyValid(person.lastName)}/>
                        <FormFeedback>
                            <Translate>error.validation.invalid_value</Translate>
                        </FormFeedback>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Person;
