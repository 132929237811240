import React from "react";
import Translate from "./Translate";

class Rebate extends React.Component {
    render() {
        /** @type {RebateModel} */
        const rebate = this.props.rebate;
        if (rebate === undefined) {
            return null;
        }

        return (
            <React.Fragment>
                {this.props.showSeparator && <hr/>}
                <div className="d-flex justify-content-between">
                    <p className="mb-2 mb-sm-0 pr-1"><Translate>rebate.title</Translate></p>
                    {this.props.showPrices && <p className="mb-2 mb-sm-0 font-weight-bold text-nowrap">
                        {rebate.charge.negate().format()}
                    </p>}
                </div>
            </React.Fragment>
        );
    }
}

export default Rebate;
