class AddonsModel {

    constructor() {
        this.selectedAddons = [];
    }

    static get addonInsurance() {
        return "insurance";
    }

    static get addons() {
        return [AddonsModel.addonInsurance];
    };

    add(addon) {
        if (this.selectedAddons.indexOf(addon) < 0) {
            this.selectedAddons.push(addon);
        }
    }

    remove(addon) {
        this.selectedAddons = this.selectedAddons.filter(a => a !== addon);
    }

    isSelected(addon) {
        return this.selectedAddons.indexOf(addon) >= 0;
    }
}

export default AddonsModel;
