import React from "react";

class ExtraCharges extends React.Component {
    render() {
        /** @type {ExtraChargesModel} */
        const charges = this.props.extraCharges;
        if (charges.isEmpty()) {
            return null;
        }

        return (
            <React.Fragment>
                {this.props.showSeparator && <hr/>}
                {charges.items.map((charge, key) =>
                    <div className="d-flex justify-content-between" key={key}>
                        <p className="mb-2 mb-sm-0 pr-1">{charge.description}</p>
                        {this.props.showPrices && <p className="mb-2 mb-sm-0 font-weight-bold text-nowrap">
                            {charge.price.format()}
                        </p>}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default ExtraCharges;
