class Validator {

    static markAs(object, isValid) {
        Object.defineProperty(object, '_isValid', {enumerable: false, writable: true});
        object._isValid = isValid;
    }

    static isValid(object) {
        return object._isValid === true;
    }

    static appendResult(object, result) {
        if (!(result instanceof Promise)) {
            console.error('validation result have to be a promise', result);
            return;
        }

        if (object._validationQueue === undefined) {
            Object.defineProperty(object, '_validationQueue', {enumerable: false, writable: true});
            object._validationQueue = result;
            return;
        }

        object._validationQueue = object._validationQueue.then(() => result);
    }

    static validate(object) {
        if (object._validationQueue === undefined) {
            return Promise.resolve(Validator.isValid(object));
        }

        return object._validationQueue;
    }
}

export default Validator;
