export default {
    "country.au": "Australien",
    "country.be": "Belgien",
    "country.dk": "Dänemark",
    "country.de": "Deutschland",
    "country.ee": "Estland",
    "country.fi": "Finnland",
    "country.fr": "Frankreich",
    "country.gr": "Griechenland",
    "country.ie": "Irland",
    "country.it": "Italien",
    "country.ca": "Kanada",
    "country.lv": "Lettland",
    "country.lt": "Litauen",
    "country.nl": "Niederlande",
    "country.nz": "Neuseeland",
    "country.no": "Norwegen",
    "country.at": "Österreich",
    "country.pl": "Polen",
    "country.pt": "Portugal",
    "country.sk": "Slowakei",
    "country.si": "Slowenien",
    "country.se": "Schweden",
    "country.ch": "Schweiz",
    "country.es": "Spanien",
    "country.cz": "Tschechische Republik",
    "country.gb": "Vereinigtes Königreich",
    "country.us": "Vereinigte Staaten",

    "select": "Auswählen",

    "page.title": "Kasse",

    "payment_method.credit_card.title": "Karte",
    "payment_method.credit_card.number": "Kartennummer",
    "payment_method.credit_card.holder": "Karteninhaber",
    "payment_method.credit_card.expiry": "Ablaufdatum",
    "payment_method.credit_card.cvc": "CVC",

    "payment_method.direct_debit.title": "SEPA Lastschrift",
    "payment_method.direct_debit.email_address": "E-Mail-Addresse",
    "payment_method.direct_debit.holder": "Kontoinhaber",
    "payment_method.direct_debit.iban": "IBAN",
    "payment_method.direct_debit.bank_name": "Name der Bank",
    "payment_method.direct_debit.bic": "BIC",

    "payment_method.ideal.title": "iDEAL",
    "payment_method.ideal.bic": "Name der Bank",

    "payment_method.sofort.title": "Sofortüberweisung",
    "payment_method.sofort.bank_country": "Land der Bank",

    "payment_method.alipay.title": "Alipay",
    "payment_method.twint.title": "TWINT",
    "payment_method.apple_pay.title": "Apple Pay",
    "payment_method.google_pay.title": "Google Pay",

    "payment_method.giropay.title": "Giropay",
    "payment_method.giropay.holder_name": "Kontoinhaber",

    "payment_method.eps.title": "EPS",
    "payment_method.eps.holder_name": "Kontoinhaber",

    "payment_method.invoice.title": "Zahlung per Rechnung",
    "payment_method.invoice.type.individual": "Privatkunde",
    "payment_method.invoice.type.company": "Geschäftskunde",
    "payment_method.invoice.first_name": "Vorname",
    "payment_method.invoice.last_name": "Nachname",
    "payment_method.invoice.company_name": "Firmenname",
    "payment_method.invoice.city": "Stadt",
    "payment_method.invoice.zip_code": "Postleitzahl",
    "payment_method.invoice.address": "Adresse",
    "payment_method.invoice.email": "E-Mail",
    "payment_method.invoice.language": "Sprache",
    "payment_method.invoice.language.de": "Deutsch",
    "payment_method.invoice.language.fr": "Französisch",
    "payment_method.invoice.region_restriction": "Die Zahlung per Rechnung ist derzeit nur für Personen mit Wohnsitz in der Schweiz möglich. Eine gültige Schweizer Postadresse ist erforderlich.",

    "payment_method.crypto.title": "Mit Kryptowährung bezahlen",

    "payment_method.postfinance.title": "PostFinance",
    "payment_method.postfinance.auth_type": "Bezahlart",
    "payment_method.postfinance.auth_type.card": "Karte / App",
    "payment_method.postfinance.auth_type.efinance": "E-Finance",
    "payment_method.postfinance.language": "Sprache",
    "payment_method.postfinance.language.de": "Deutsch",
    "payment_method.postfinance.language.fr": "Französisch",
    "payment_method.postfinance.language.it": "Italienisch",
    "payment_method.postfinance.language.en": "Englisch",

    "payment_method.klarna.title": "Klarna",
    "payment_method.klarna.country": "Land des Käufers",
    "payment_method.klarna.email_address": "E-Mail Adresse des Käufers",

    "insurance.refund_protect.title": "Erstattungsfähige Buchung",
    "insurance.trekksoft_standard.title": "Annulations Versicherung",

    "insurance.description.line1": "Um das finanzielle Risiko bei einer Annullierung nicht selber tragen zu müssen, empfehlen wir Ihnen den Abschluss unserer Annullierungskostenversicherung.",
    "insurance.description.line2": "Versicherte Ereignisse",
    "insurance.description.line3": "(Auszug aus den Allgemeinen Versicherungsbedingungen)",
    "insurance.description.line4": "Die genauen Leistungen finden Sie in den",
    "insurance.description.line5": "Allgemeinen Versicherungsbedingungen",
    "insurance.description.line6": "Bei Fragen rufen Sie uns an oder schreiben ein Mail",
    "insurance.coverage.line1": "Krankheit, Schwangerschaft, Unfall, Todesfall",
    "insurance.coverage.line2": "Beeinträchtigung des Wohneigentums zuhause",
    "insurance.coverage.line3": "Verspätung oder Ausfall des Transportmittels oder Fahrzeuges auf der Anreise",
    "insurance.coverage.line4": "Streiks und Gefahren an der Reisedestination",
    "insurance.coverage.line5": "Arbeitslosigkeit / unerwarteter Stellenantritt",

    "cart.title": "Warenkorb",
    "cart.show": "Warenkorb zeigen",
    "cart.hide": "Warenkorb verstecken",

    "person.title": "Gast",
    "person.firstname": "Vorname",
    "person.lastname": "Nachname",

    "terms.dialog.title": "Allgemeinen Geschäftsbedingungen",

    "cvc.help.title": "Wo kann ich meine CVV / CVC Nummer finden?",
    "cvc.help.maestro": "Belgian debit cards do not have a Card Verification Code (CVC). To confirm the payment, please leave it blank. /  Enter 000 or 999 to confirm payment.",

    "dialog.button.close": "Schliessen",

    "error.payment_method.no_payment_method_is_configured": "Es ist keine Zahlungsmethode konfiguriert. Bitte kontaktieren Sie den Verkäufer.",
    "error.validation.invalid_value": "Ungültiger Wert",
    "error.validation.check_input": "Bitte prüfen Sie Ihre Eingaben",
    "error.fatal.title": "Ein unerwarteter Fehler ist aufgetreten.",
    "error.fatal.description": "Entschuldigung, leider ist ein unerwarteter Fehler aufgetreten. Bitte probieren Sie es nochmals or kontaktieren uns.",
    "error.fatal.message": "Fehler Meldung",
    "error.payment.payment_failed": "Zahlung fehlgeschlagen. Bitte probieren Sie es nochmals oder kontaktieren Sie uns.",
    "error.payment.3ds_authentication_failed": "3DS-Authentifizierung fehlgeschlagen. Bitte probieren Sie es nochmals oder kontaktieren Sie uns.",

    "step.title.addons": "Erstattungsfähige Zahlung",
    "step.title.payment": "Zahlungsmethode",
    "step.title.google_pay": "Google Pay",

    "step.breadcrumb.addons": "Erstattungsfähige Zahlung",
    "step.breadcrumb.payment": "Zahlung",
    "step.breadcrumb.google_pay": "Google Pay",

    "action.next": "Weiter",
    "action.pay": "Bezahlen",
    "action.more": "Mehr infos",

    "action.abort.title": "Die Zahlung abbrechen",

    "dcc.title": "Wählen Sie die zu zahlende Währung",
    "dcc.alternate_payment_method_description": "Diese alternative Zahlungsmethode erlaubt nur %currency% als Währung. Wenn Sie in einer anderen Währung bezahlen möchten, wählen Sie bitte eine andere Zahlungsmethode.",
    "dcc.fees_info": "beinhaltet alle Gebühren",
    "dcc.conversion_by_payyo": "%price%, Wechsel durch Payyo",
    "dcc.conversion_by_issuer": "%price%, Wechsel durch Aussteller der Karte",

    "footer.line1": "Bitte kontaktieren Sie %supportEmail% für alle Fragen rund um die Zahlung.",
    "footer.line2": "© 2010 - %year% TrekkSoft AG. Made in Switzerland.",

    "countdown.title": "%time% verbleibt, um die Zahlung abzuschließen.",

    "rebate.title": "Rebate",

    "refund_protect.recommended": "Empfohlen",
    "refund_protect.protection_selected": "Erstattungsfähige Zahlung",
    "refund_protect.instructions_1": "Werten Sie Ihre Buchung auf und erhalten Sie eine 100%ige Rückerstattung, bei einer Stornierung bis zum Servicetermin, wenn Sie aus einer der in unseren AGB definierten Gründe nicht teilnehmen und entsprechende Nachweise liefern können.",
    "refund_protect.instructions_2": "Powered by",
    "refund_protect.instructions_powered_by": "Refundable.me",
    "refund_protect.reasons.covid_19": "COVID-19 Infektion und Isolierung",
    "refund_protect.reasons.covid_19_link": "Siehe Details",
    "refund_protect.reasons.reasons": "Beantragen Sie die volle Erstattung im Falle von",
    "refund_protect.reasons.reason_0": "Kurzfristige Krankheit und Verletzung (einschließlich COVID-19)",
    "refund_protect.reasons.reason_1": "Krankheit, Unfall und Verletzung",
    "refund_protect.reasons.reason_2": "Häusliche und familiäre Notfälle",
    "refund_protect.reasons.reason_3": "Versagen des Transports",
    "refund_protect.reasons.reason_4": "Diebstahl von Dokumenten",
    "refund_protect.reasons.reason_5": "Vorbestehende medizinische Erkrankung",
    "refund_protect.reasons.reason_6": "Gerichtsvorladung",
    "refund_protect.reasons.reason_7": "Versagen des Privatfahrzeugs",
    "refund_protect.reasons.reason_8": "Rückruf der Notdienste",
    "refund_protect.reasons.reason_9": "Und viele mehr",
    "refund_protect.reasons.see_more": "Und viele mehr",
    "refund_protect.protection_rejected": "Grundlegende Erstattungsbedingungen",
    "refund_protect.protection_rejected_terms": "Ich akzeptiere die Standard-Rückerstattungsbedingungen des Anbieters und stimme ihnen zu."
};
