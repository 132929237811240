import React from "react";
import Color from "color";

class Styling extends React.Component {

    constructor(props) {
        super(props);
        const styling = props.styling;
        if (styling.faviconUrl) {
            this.applyFavicon(styling.faviconUrl);
        }
        if (styling.accentColor instanceof Color) {
            this.applyAccentColor(styling.accentColor);
        }
    }

    getHead() {
        return document.getElementsByTagName("head")[0];
    }

    applyFavicon(url) {
        const link = document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "icon";
        link.href = url;

        this.removeExistingFavicons();
        this.getHead().appendChild(link);
    }

    removeExistingFavicons() {
        const head = this.getHead();
        const links = head.getElementsByTagName("link");
        const remove = [];

        for (let i = 0; i < links.length; i++) {
            if (/\bicon\b/i.test(links[i].getAttribute("rel"))) {
                remove.push(links[i]);
            }
        }

        remove.map(link => head.removeChild(link));
    }

    applyAccentColor(color) {
        const element = document.createElement("style");
        let style = "";

        style += ".custom-checkbox .custom-control-input:checked~.custom-control-label:before {background-color: " + color + "}";
        style += ".custom-radio .custom-control-input:checked~.custom-control-label:before {background-color: " + color + "}";
        style += ".custom-control-input:active ~ .custom-control-label:before {background-color: " + color.lighten(.35) + "}";
        style += ".custom-control-input:not(:disabled):active ~ .custom-control-label::before {background-color: " + color.lighten(.35) + "; border-color: " + color.lighten(.35) + "}";
        style += ".breadcrumb-item.active {color: " + color + "}";
        style += ".spinner-global:before {border-color: " + color.fade(.7) + "; border-top-color: " + color + ";}";
        style += ".btn-pink, .btn-pink.disabled, .btn-pink:disabled, .btn-pink:focus, .btn-pink.focus {background-color: " + color + "; border-color: " + color + "}";
        style += ".btn-pink:hover {background-color: " + color.darken(.075) + ";border-color: " + color.darken(.075) + "}";
        style += ".btn-pink:not(:disabled):not(.disabled).active, .btn-pink:not(:disabled):not(.disabled):active, .show > .btn-pink.dropdown-toggle {background-color: " + color.darken(0.1) + ";border-color: " + color.darken(0.125) + "}";

        element.innerHTML = style;

        this.getHead().appendChild(element);
    }

    render() {
        return null;
    }
}

export default Styling;
